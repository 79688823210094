//---------------------CONSTANTS---------------------------
export const LOADING_IMAGE = 'LOADING_IMAGE';
export const ERROR_IMAGE = 'ERROR_IMAGE';
export const SHOW_AVAILABILITY = 'SHOW_AVAILABILITY';
export const IS_LOGIN_MODAL = 'IS_LOGIN_MODAL';
export const IS_SCROLL = 'IS_SCROLL';
export const CAMPAIGN_INFO = 'CAMPAIGN_INFO';
export const IS_ELICASH_MODAL = 'ELICASH_MODAL';
export const GUEST_DETAILS = 'GUEST_DETAILS';
export const USE_ELICASH = 'USE_ELICASH';
export const APPLY_ELICASH = 'APPLY_ELICASH';

//--------------------ACTION-------------------------------

export function showLoadingImage(status) {
  return {
    type: LOADING_IMAGE,
    loader: status,
  };
}

export function showErrorImage(status, errorMessage) {
  return {
    type: ERROR_IMAGE,
    error: status,
    errorMessage: errorMessage,
  };
}

export function showAvailability(status) {
  return {
    type: SHOW_AVAILABILITY,
    showAvailabilty: status,
  };
}

export function isLoginModalOpen(status) {
  return {
    type: IS_LOGIN_MODAL,
    open: status,
  };
}

export function isElicashOpen(status) {
  return {
    type: IS_ELICASH_MODAL,
    isOpen: status,
  };
}

export function isScrollListing(status) {
  return {
    type: IS_SCROLL,
    isScroll: status,
  };
}
export function campaignInfo(utmCampaign, utmSource) {
  return {
    type: CAMPAIGN_INFO,
    utmCampaign: utmCampaign,
    utmSource: utmSource,
  };
}
export function useElicash(elicashCount) {
  return {
    type: USE_ELICASH,
    elicashCount: elicashCount,
  };
}
export function guestDetails(data) {
  return {
    type: GUEST_DETAILS,
    info: data,
  };
}

export function applyElicash(status) {
  return {
    type: APPLY_ELICASH,
    elicashApplied: status,
  };
}
//------------------------ACTION CREATOR -------------------------

export const loadingImage = (status) => {
  return (dispatch) => {
    dispatch(showLoadingImage(status));
  };
};

export const errorImage = (status, errorMessage) => {
  return (dispatch) => {
    dispatch(showErrorImage(status, errorMessage));
  };
};

export const checkAvailabilityLoader = (status) => {
  return (dispatch) => {
    dispatch(showAvailability(status));
  };
};

export const setOpen = (status) => {
  return (dispatch) => {
    dispatch(isLoginModalOpen(status));
  };
};

export const setElicashOpen = (status) => {
  return (dispatch) => {
    dispatch(isElicashOpen(status));
  };
};

export const scrollingListing = (status) => {
  return (dispatch) => {
    dispatch(isScrollListing(status));
  };
};

export const campaignUpdate = (utmCampaign, utmSource) => {
  return (dispatch) => {
    dispatch(campaignInfo(utmCampaign, utmSource));
  };
};

export const useElicashUpdate = (elicashCount) => {
  return (dispatch) => {
    dispatch(useElicash(Number(elicashCount)));
  };
};

export const applyElicashStatusUpdate = (status) => {
  return (dispatch) => {
    dispatch(applyElicash(status));
  };
};

export const setGuestDetails = (details) => {
  return (dispatch) => {
    dispatch(guestDetails(details));
  };
};
//----------------------ACTION HANDLERS -------------------

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_IMAGE:
      return {
        ...state,
        loader: action.loader,
      };
    case ERROR_IMAGE:
      return {
        ...state,
        error: action.error,
        errorMessage: action.errorMessage,
      };
    case SHOW_AVAILABILITY:
      return {
        ...state,
        showAvailabilty: action.showAvailabilty,
      };
    case IS_LOGIN_MODAL:
      return {
        ...state,
        open: action.open,
      };

    case IS_ELICASH_MODAL:
      return {
        ...state,
        isOpen: action.isOpen,
      };
    case IS_SCROLL:
      return {
        ...state,
        isScroll: action.isScroll,
      };
    case CAMPAIGN_INFO:
      return {
        ...state,
        utmCampaign: action.utmCampaign,
        utmSource: action.utmSource,
      };
    case USE_ELICASH:
      return {
        ...state,
        elicashCount: action.elicashCount,
      };
    case APPLY_ELICASH:
      return {
        ...state,
        elicashApplied: action.elicashApplied,
      };
    case GUEST_DETAILS:
      return {
        ...state,
        guestDetails: action.info,
      };
    default:
      return state;
  }
};

//------------------INITIAL STATES-----------------------------

const initialState = {
  loader: false,
  error: false,
  errorMessage: '',
  showAvailabilty: false,
  open: false,
  isOpen: false,
  isScroll: false,
  utmCampaign: '',
  elicashCount: 0,
  elicashApplied: false,
  utmSource: '',
  guestDetails: {},
};

export default globalReducer;
