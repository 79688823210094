import Header from '@components/Header';
import PropertyList from '@components/PropertyList/PropertyList';
import { useMediaQuery, useTheme } from '@mui/material';
import { fetchPropertyList, fetchCityList } from '@reducers/propertyReducer';
import HelmetConfig from '@seo/HelmetConfig';
import {
  saveFilterValues,
  isCityNameValid,
  getValidCityName,
} from '@utils/common';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const PropertyListing = () => {
  const dispatch = useDispatch();
  const urlLocation = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const searchParams = new URLSearchParams(urlLocation.search);
  const adult_count = Number(searchParams.get('adults'));
  const children_count = Number(searchParams.get('children'));
  const checkin_date = searchParams.get('checkin');
  const checkout_date = searchParams.get('checkout');
  const [isFetched, setIsFetched] = useState(true);

  var city_name;
  if (urlLocation.pathname) {
    const pathValues = urlLocation.pathname.split('/');
    if (pathValues.length > 2) {
      const cityPathValues = pathValues[2].split('-');
      let fetchedCityName;
      if (cityPathValues.length > 3) {
        fetchedCityName = cityPathValues.slice(2).join(' ');
      } else {
        fetchedCityName = cityPathValues[2];
      }

      city_name = fetchedCityName.toLowerCase();
    }
  }
  const propertyListData = useSelector(
    (state) => state?.property?.propertyListData
  );

  const { cityList } = useSelector((state) => state.property);
  const cityListData = cityList?.data;

  const propertyList = propertyListData?.data;
  const noProperties = propertyListData?.data?.properties?.length === 0;

  const fetchData = (isCityValid) => {
    const queryParams = {
      checkin_date,
      checkout_date,
      city_name: getValidCityName(city_name, isCityValid),
      adult_count,
      children_count,
    };

    const queryString = Object.entries(queryParams)
      .filter(([_, value]) => value)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    dispatch(fetchPropertyList(queryString));
    setIsFetched(false);
  };

  useEffect(() => {
    if (cityListData && isFetched) {
      const isCityValid = isCityNameValid(cityListData, city_name);
      fetchData(isCityValid);
      if (
        (city_name || checkin_date || checkout_date) &&
        !localStorage.getItem('filterValues')
      ) {
        const filterValues = {
          selectedCity: city_name,
          checkInDate:
            checkin_date &&
            dayjs(checkin_date, { format: 'DD MMM, YYYY' }).format(
              'DD MMM, YYYY'
            ),
          checkOutDate:
            checkout_date &&
            dayjs(checkout_date, { format: 'DD MMM, YYYY' }).format(
              'DD MMM, YYYY'
            ),
          numberOfGuests: adult_count || 1,
          numberOfChildren: children_count || 0,
        };

        saveFilterValues(filterValues);
      }
    }
  }, [cityListData]);

  useEffect(() => {
    if (!cityListData) {
      dispatch(fetchCityList());
    }
  }, [cityListData]);

  return (
    <>
      <HelmetConfig
        title='ELIVAAS | Property Details'
        description='ELIVAAS | Making your vacation home our first priority'
        keywords='property, page, keywords'
      />
      {!isFetched && !isMobile && (
        <Header
          sticky={true}
          fixed={false}
          logoColor={!noProperties ? 'white' : 'black'}
          className={noProperties ? 'el-header el-detailHeader' : 'el-header'}
          scrollDrop={false}
          isAbsolute={true}
        />
      )}

      <PropertyList propertyList={propertyList} cityListData={cityListData} />
    </>
  );
};

export default PropertyListing;
