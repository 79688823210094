import { instance } from '../instance/axios';

import {
  fetchingCoupon,
  gettingCoupon,
  couponAppliedStatus,
  hollowCouponAppliedStatus,
  customCouponAppliedStatus,
  customDoubleDipCouponAppliedStatus,
  isDoubleDipCouponapplicableStatus,
  gettingCouponResponse,
  applyingCouponId,
  applyingCouponCode,
  removeCouponStatus,
  FETCHING_COUPON_LISTING,
  GEETING_COUPON_LISTING,
  IS_COUPON_APPLIED,
  IS_HOLLOW_COUPON_APPLIED,
  IS_CUSTOM_COUPON_APPLIED,
  IS_CUSTOM_DOUBLE_DIP_COUPON_APPLIED,
  IS_DOUBLE_DIP_COUPON_APPLICABLE,
  GETTING_COUPON_RESPONSE,
  GETTING_APPLIED_COUPON_ID,
  GETTING_APPLIED_COUPON_CODE,
  FETCHING_REMOVE_COUPON,
  FETCHING_REMOVE_HOLLOW_COUPON,
  FETCHING_REMOVE_CUSTOM_DOUBLE_DIP_COUPON,
  FETCHING_REMOVE_CUSTOM_COUPON,
} from '@actions/couponAction';

import { handleErrorResponse } from '@utils/common';
import { loadingImage } from './globalReducers';
import { NULLABLE_KEY } from '@utils/constants';

//---------------------INITIAL STATE-------------------
const initialState = {
  fetchingCouponList: false,
  gettingCouponList: [],
  isCouponApplied: false,
  isCustomCouponApplied: false,
  isCustomDoubleDipCouponApplied: false,
  isHollowCouponApplied: false,
  gettingCouponResponse: '',
  couponId: '',
  couponCode: '',
  isCouponRemoved: { value: false },
  isHollowCouponRemoved: { value: false },
  isCustomCouponRemoved: { value: false },
  isCustomDoubleDipCouponRemoved: { value: false },
  isDoubleDipCouponApplicable: false,
};

export const couponList = (id) => async (dispatch) => {
  try {
    dispatch(loadingImage(true));
    const url = `gateways/customer/class/offers/functions/getOffers${
      id !== NULLABLE_KEY ? `?property_id=${id}` : ''
    }`;
    const response = await instance.get(url);
    if (response?.status === 200) {
      dispatch(loadingImage(false));
      dispatch(fetchingCoupon(true));
      dispatch(gettingCoupon(response?.data));
    }
  } catch (error) {
    handleErrorResponse(error, dispatch);
  }
};

export const couponById = (id, propertyId) => async (dispatch) => {
  try {
    dispatch(loadingImage(true));
    const url = `gateways/customer/class/offers/functions/getOrderOffers?order_id=${id}`;
    if (id) {
      const response = await instance.get(url);
      if (response?.status === 200) {
        dispatch(loadingImage(false));
        dispatch(fetchingCoupon(true));
        dispatch(gettingCoupon(response?.data));
      }
    }
  } catch (error) {
    handleErrorResponse(error, dispatch);
  }
};

export const couponResponse = (status) => {
  return (dispatch) => {
    dispatch(gettingCouponResponse(status));
  };
};

export const couponApplied = (status, isHollowCoupon) => {
  return (dispatch) => {
    if (status == false && !isHollowCoupon) {
      dispatch(appliedCouponId(''));
    }
    dispatch(couponAppliedStatus(status));
  };
};

export const HollowCouponApplied = (status, isSitewideCoupon) => {
  return (dispatch) => {
    if (status == false && !isSitewideCoupon) {
      dispatch(appliedCouponId(''));
    }
    dispatch(hollowCouponAppliedStatus(status));
  };
};

export const customCouponApplied = (status) => {
  return (dispatch) => {
    dispatch(customCouponAppliedStatus(status));
  };
};

export const customDoubleDipCouponApplied = (status) => {
  return (dispatch) => {
    dispatch(customDoubleDipCouponAppliedStatus(status));
  };
};

export const doubleDipCouponApplicable = (status) => {
  return (dispatch) => {
    dispatch(isDoubleDipCouponapplicableStatus(status));
  };
};

export const appliedCouponId = (id) => {
  return (dispatch) => {
    dispatch(applyingCouponId(id));
  };
};

export const appliedCustomCouponCode = (id) => {
  return (dispatch) => {
    dispatch(applyingCouponCode(id));
  };
};

export const removeCoupon = (data) => async (dispatch) => {
  try {
    const url = `gateways/customer/class/orders/functions/deleteOrderCouponByCouponId?order_id=${data.orderId}&coupon_id=${data.couponId}`;
    const response = await instance.delete(url);
    if (response?.status === 200) {
      console.log('delete success', data);

      dispatch(removeCouponStatus(data));
      if (data.type == 'SiteWide') {
        dispatch(appliedCouponId(''));
      } else if (data.type == 'HollowCoupon') {
        dispatch(appliedCouponId(''));
      } else {
        if (data.type == 'CustomCoupon') {
          dispatch(appliedCouponId(''));
        }
        dispatch(appliedCustomCouponCode(''));
      }
    }
  } catch (error) {
    handleErrorResponse(error, dispatch);
  }
};
//--------------------REDUCER-------------------------
const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_COUPON_LISTING:
      return {
        ...state,
        fetchingCouponList: action.payload,
      };
    case GEETING_COUPON_LISTING:
      return {
        ...state,
        gettingCouponList: action.payload,
      };
    case IS_COUPON_APPLIED:
      return {
        ...state,
        isCouponApplied: action.payload,
      };
    case IS_HOLLOW_COUPON_APPLIED:
      return {
        ...state,
        isHollowCouponApplied: action.payload,
      };
      s;
    case IS_CUSTOM_COUPON_APPLIED:
      return {
        ...state,
        isCustomCouponApplied: action.payload,
      };
    case IS_CUSTOM_DOUBLE_DIP_COUPON_APPLIED:
      return {
        ...state,
        isCustomDoubleDipCouponApplied: action.payload,
      };
    case IS_DOUBLE_DIP_COUPON_APPLICABLE:
      return {
        ...state,
        isDoubleDipCouponApplicable: action.payload,
      };

    case GETTING_COUPON_RESPONSE:
      return {
        ...state,
        gettingCouponResponse: action.payload,
      };
    case GETTING_APPLIED_COUPON_ID:
      return {
        ...state,
        couponId: action.payload,
      };
    case GETTING_APPLIED_COUPON_CODE:
      return {
        ...state,
        couponCode: action.payload,
      };
    case FETCHING_REMOVE_COUPON:
      return {
        ...state,
        isCouponRemoved: { value: action.payload },
      };
    case FETCHING_REMOVE_HOLLOW_COUPON:
      return {
        ...state,
        isHollowCouponRemoved: { value: action.payload },
      };
    case FETCHING_REMOVE_CUSTOM_COUPON:
      return {
        ...state,
        isCustomCouponRemoved: { value: action.payload },
      };
    case FETCHING_REMOVE_CUSTOM_DOUBLE_DIP_COUPON:
      return {
        ...state,
        isCustomDoubleDipCouponRemoved: { value: action.payload },
      };
    default:
      return state;
  }
};

export default couponReducer;
