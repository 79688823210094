import { createTheme } from '@mui/material/styles';

// Define your custom theme
export const theme = createTheme({
  palette: {
    primary: {
      main: '#ef4444', // Custom primary color
    },
    secondary: {
      main: '#364d59', // Custom secondary color
    },
    dafault: {
      main: '#364d59', // Custom default color
    },
    info: {
      main: '#ffd565', // Custom warning color
    },
    success: {
      main: '#059652', // Custom warning color
    },
    error: {
      main: '#ab221d', // Custom warning color
    },
    typography: {
      p: {
        color: '#ffd565',
      },
    },
  },
  typography: {
    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
  },
});

export default theme;
