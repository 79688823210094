import { instance } from '../instance/axios';
import {
  IS_LOGGED_IN_STATUS,
  LOGGED_IN_DATA,
  IS_OTP_MODAL_OPEN,
  IS_VALIDATE_OTP,
  VALIDATE_OTP_RES,
  FETCHING_VALIDATE_OTP,
  FIRST_TIME_USER,
  OTP_ERROR_RES,
  fetchingLoggedIn,
  gettingLoggedInData,
  isOtpModal,
  fetchingValidateOtp,
  validateOtp,
  fetchingResendOtp,
  firstTime,
  otpError,
} from '@actions/authAction';
import Cookies from 'universal-cookie';
import { getProfileDetail } from './profileReducer';
import { windowReload } from '@utils/common';
import { handleErrorResponse } from '@utils/common';

const cookies = new Cookies();

//-------------CONSTANTS---------------

import {
  checkAvailabilityLoader,
  errorImage,
  loadingImage,
} from './globalReducers';

//---------------------INITIAL STATE-------------------

const initialState = {
  isLoggedInStatus: false,
  loggedInData: {},
  isOtpModalOpen: false,
  isValidateOtp: false,
  validateOtpRes: {},
  isResendOtp: false,
  isFirstTimeUser: false,
  otpErrorRes: {},
};

export const loggedIn = (data) => {
  return async (dispatch) => {
    dispatch(fetchingLoggedIn(true));
    dispatch(fetchingResendOtp(true));
    instance({
      method: 'POST',
      url: `gateways/guest/class/authentication/functions/generateOtp`,
      data: data,
    })
      .then((response) => {
        dispatch(fetchingLoggedIn(false));
        dispatch(gettingLoggedInData(response?.data));
        dispatch(isOtpModal(true));
      })
      .catch((error) => {
        handleErrorResponse(error, dispatch);
      });
  };
};

export const sendOtp = (data) => {
  return async (dispatch) => {
    dispatch(fetchingResendOtp(true));
    instance({
      method: 'POST',
      url: `gateways/guest/class/authentication/functions/validateOtp`,
      data: data,
    })
      .then((response) => {
        dispatch(fetchingValidateOtp(false));
        // cookies.set('accessToken', response?.data?.access_token, {
        //   maxAge: 60 * 60 * 60,
        //   sameSite: 'None',
        //   secure: true, // Set to true if served over HTTPS
        //   // domain: process.env.APP_GATEWAY_URL, // Set your domain
        // });
        //cookies.set('refreshToken', response?.data?.refresh_token);

        localStorage.setItem('accessToken', response?.data?.access_token);
        localStorage.setItem('refreshToken', response?.data?.refresh_token);

        dispatch(validateOtp(response?.data));
        if (response?.data?.state === 'user-created') {
          dispatch(firstTime(true));
          dispatch(isOtpModal(false));
        } else if (response?.data?.state === 'user-exists') {
          //dispatch(getProfileDetail());
          windowReload();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(otpError(error?.response?.data));
        } else {
          handleErrorResponse(error, dispatch);
        }
      });
  };
};

//--------------------REDUCER-------------------------
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOGGED_IN_STATUS:
      return {
        ...state,
        isLoggedInStatus: action.payload,
      };
    case LOGGED_IN_DATA:
      return {
        ...state,
        loggedInData: action.payload,
      };
    case IS_OTP_MODAL_OPEN:
      return {
        ...state,
        isOtpModalOpen: action.payload,
      };
    case IS_VALIDATE_OTP:
      return {
        ...state,
        isValidateOtp: action.payload,
      };
    case VALIDATE_OTP_RES:
      return {
        ...state,
        validateOtpRes: action.payload,
      };
    case FETCHING_VALIDATE_OTP:
      return {
        ...state,
        isResendOtp: action.payload,
      };
    case FIRST_TIME_USER: {
      return {
        ...state,
        isFirstTimeUser: action.payload,
      };
    }
    case OTP_ERROR_RES: {
      return {
        ...state,
        otpErrorRes: action.payload,
      };
    }
    default:
      return state;
  }
};

export default authReducer;
