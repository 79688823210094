import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const instance = axios.create({
  baseURL: process.env.APP_GATEWAY_URL,
});

const refreshToken = localStorage.getItem('refreshToken');
let isRefreshing = false;
let refreshSubscribers = [];

// Function to refresh the access token using the refresh token
const refreshAccessToken = async () => {
  try {
    const response = await instance.post(
      '/gateways/guest/class/authentication/functions/getAccessAndRefreshToken',
      {
        refresh_token: refreshToken,
      }
    );
    const newAccessToken = response.data.access_token;
    // Update the access token in the cookies
    localStorage.setItem('accessToken', newAccessToken);
    // cookies.set('accessToken', newAccessToken, {
    //   maxAge: 60 * 60 * 60,
    //   sameSite: 'None',
    //   secure: true, // Set to true if served over HTTPS
    //   //domain: process.env.APP_GATEWAY_URL, // Set your domain
    // });
    //cookies.set('accessToken', newAccessToken);
    // Call all the subscribers with the new access token
    refreshSubscribers.forEach((subscriber) => subscriber(newAccessToken));
    // Clear the subscribers array
    refreshSubscribers = [];
  } catch (error) {
    // Handle the token refresh error
    console.error('Token refresh failed:', error);
    throw error; // Propagate the error for further handling
  } finally {
    isRefreshing = false;
  }
};

// Add request interceptor to attach the access token to requests
instance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

// Add response interceptor to handle token expiration, refresh, and other errors globally
instance.interceptors.response.use(
  (response) => {
    // If the response is successful, return it directly
    return response;
  },
  async (error) => {
    console.error('Global Axios Error:', error);

    // Extract relevant information from the error
    const originalRequest = error.config;
    const statusCode = error.response?.status;

    // Check if the error is due to token expiration (status code 401)
    if (statusCode === 401 && !originalRequest._retry && !isRefreshing) {
      originalRequest._retry = true;
      isRefreshing = true;

      try {
        // Refresh the access token
        await refreshAccessToken();

        // Retry the original request with the new access token
        const response = await instance(originalRequest);
        return response;
      } catch (refreshError) {
        console.error('Token refresh failed:', refreshError);
        // Handle token refresh error if needed
      } finally {
        isRefreshing = false;
      }
    }

    // Handle other errors
    return Promise.reject(error);
  }
);

export { instance };
