import { Button, InputLabel, TextField, Typography, Link } from '@mui/material';
import { loggedIn, sendOtp } from '@reducers/authReducer';
import useAnalytics from '@components/Common/Analytics';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './loginField.scss';

export const LoginForm = (props) => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const {
    isLoggedInStatus,
    loggedInData,
    isOtpModalOpen,
    isValidateOtp,
    validateOtpRes,
    isResendOtp,
    isFirstTimeUser,
    otpErrorRes,
  } = useSelector((state) => state.auth);
  const { guestDetails } = useSelector((state) => state.glob);

  const {
    backtoLogin,
    setBacktoLogin,
    setOpen,
    loginDisabled,
    setLoginDisabled,
  } = props;
  const [mobileNumber, setMobileNumber] = useState(guestDetails?.mobile || '');
  const [otp, setOTP] = useState('');
  const [showLoginField, setShowLoginField] = useState(false);
  const [otpDisabled, setOtpDisabled] = useState(true);
  const [otpError, setOtpError] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [resendCount, setResendCount] = useState(30);
  const [isNewUser, setNewUser] = useState(false);

  useEffect(() => {
    const isOtpError = Object.keys(otpErrorRes).length > 0;
    setOtpError(isOtpError);
  }, [otpErrorRes]);

  const handleMobileSubmit = (e) => {
    e.preventDefault();
    setOtpError(false);
    if (validateMobileNumber(mobileNumber)) {
      trackEvent('Login Attempt', {
        method: 'OTP',
        mobile_number: mobileNumber,
      });
      try {
        let data = {
          mobile: mobileNumber,
        };
        dispatch(loggedIn(data));
        setResendCount(30);
      } catch (error) {
        console.error('Error logging in:', error);
      }
    } else {
      console.log('Invalid mobile number');
    }
  };

  const handleLogin = () => {
    if (validateOTP(otp)) {
      let data = {
        mobile: mobileNumber,
        otp: parseInt(otp),
        state: loggedInData?.state,
      };
      dispatch(sendOtp(data));
      trackEvent('Login Outcome', {
        mobile_number: mobileNumber,
        otp: parseInt(otp),
        state: loggedInData?.state,
      });
      // setOpen(false);
      // Perform login logic here
    } else {
      setOtpError(true);
      // Display an error message or handle the invalid OTP case
    }
  };

  const handleChange = (e) => {
    const inputMobileNumber = e.target.value;
    if (inputMobileNumber.length === 11) {
      return;
    }
    setMobileNumber(inputMobileNumber);
    if (validateMobileNumber(inputMobileNumber)) {
      trackEvent('Number Validated', {
        mobile_number: inputMobileNumber,
      });
      setLoginDisabled(false);
    } else {
      setLoginDisabled(true);
    }
  };

  const handleChangeOTP = (e) => {
    setOtpError(false);
    const inputOTP = e.target.value;
    if (inputOTP.length === 7) {
      return;
    }
    setOTP(inputOTP);
    if (validateOTP(inputOTP)) {
      setOtpDisabled(false);
    } else {
      setOtpDisabled(true);
    }
  };

  const validateMobileNumber = (number) => {
    return /^[6789]\d{9}$/.test(number);
  };

  const validateOTP = (otp) => {
    // Basic validation: Check if the OTP is a 6-digit number
    return /^\d{6}$/.test(otp);
  };

  const handleResendOTP = () => {
    setResendDisabled(true);
    setResendCount(30);
  };

  useEffect(() => {
    let timer = null;
    if (isOtpModalOpen || isResendOtp) {
      setResendDisabled(true);

      timer = setInterval(() => {
        setResendCount((prevCount) => prevCount - 1);
      }, 1000); // Reduce count every second
    }
    if (resendCount <= 0) {
      clearInterval(timer);
      setResendDisabled(false);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isOtpModalOpen, isResendOtp, resendCount, showLoginField]);

  useEffect(() => {
    if (mobileNumber) {
      if (validateMobileNumber(mobileNumber)) {
        trackEvent('Number Validated', {
          mobile_number: mobileNumber,
        });
        setLoginDisabled(false);
      } else {
        setLoginDisabled(true);
      }
    }
  }, []);

  useEffect(() => {
    if (isOtpModalOpen) {
      setShowLoginField(true);
      setOTP('');
      setBacktoLogin(false);
      setLoginDisabled(true);
      setOtpDisabled(true);
    }
  }, [loggedInData, isOtpModalOpen]);

  useEffect(() => {
    if (Object.keys(validateOtpRes).length > 0 && !isFirstTimeUser) {
      dispatch(setOpen(false));
    }
  }, [validateOtpRes]);

  return (
    <div className='el-loginFieldMain EL-loginForm'>
      {(!showLoginField || backtoLogin) && (
        <div className='el-loginField'>
          <InputLabel>Enter Mobile Number to Login / Sign up</InputLabel>
          <TextField
            margin='dense'
            placeholder='Enter 10 digit mobile no.'
            type='number'
            size='small'
            fullWidth
            color='secondary'
            value={mobileNumber}
            onChange={handleChange}
          />
          <Button
            variant='contained'
            fullWidth
            onClick={handleMobileSubmit}
            color='secondary'
            size='large'
            disabled={loginDisabled || isLoggedInStatus}
            sx={{ mt: 2 }}
          >
            Continue
          </Button>
          {/* <Divider sx={{ mt: 4, mb: 4 }}>OR</Divider>
          <Button
            className='el-googleLogin'
            fullWidth
            size='large'
            variant='outlined'
            startIcon={<img src={GoogleLogin} size={40} alt='login' />}
          >
            Continue with Google
          </Button> */}
        </div>
      )}
      {showLoginField && !backtoLogin && (
        <div className='el-loginBox el-loginField'>
          <InputLabel shrink>
            We’ve sent a verification code to <span>+91-{mobileNumber}</span>
          </InputLabel>
          <TextField
            margin='dense'
            placeholder='OTP'
            type='number'
            size='small'
            color='secondary'
            fullWidth
            value={otp}
            onChange={handleChangeOTP}
            maxLength={6}
            error={otpError}
            helperText={otpError ? 'Incorrect OTP. Please try again.' : ''}
          />
          <Button
            variant='contained'
            fullWidth
            onClick={handleLogin}
            color='secondary'
            size='large'
            sx={{ mt: 2 }}
            disabled={otpDisabled || isValidateOtp}
          >
            Confirm
          </Button>
          <div className='el_resendOtp'>
            {resendDisabled ? (
              <Typography gutterBottom variant='p' component='p'>
                Resend OTP in {resendCount} sec
              </Typography>
            ) : (
              <Button
                color='secondary'
                variant='text'
                disabled={resendDisabled}
                onClick={handleMobileSubmit}
              >
                Resend OTP
              </Button>
            )}
          </div>
        </div>
      )}
      <div className='el-loginAction'>
        By proceeding you agree to our{' '}
        <Link href='/explore/privacy-policy'> Privacy Policy </Link>, Agreement
        and <Link href='/explore/terms-and-conditions'>T&Cs</Link>.
      </div>
    </div>
  );
};

export default LoginForm;
