import { ThemeProvider } from '@mui/material/styles';
import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import Footer from '@components/Footer';
import '@styles/default.scss';
import theme from './theme';

const MainLayout = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className='mainPage'>
        <Outlet />
        <Suspense fallback={<div></div>}>
          <Footer />
        </Suspense>
      </div>
    </ThemeProvider>
  );
};

export default MainLayout;
