import * as React from 'react';
import PropTypes from 'prop-types';

import { Tabs, Box, Typography, Tab } from '@mui/material';

import { MyWalletItems } from './WalletItems';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function MyWalletTabs(props) {
  const { walletList } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Transform the array into an object grouped by status
  const groupedWalletHistory = walletList?.reduce((acc, item) => {
    // Initialize the array for each status if it doesn't exist
    if (!acc[item.status]) {
      acc[item.status] = [];
    }
    // Add the item to the appropriate status array
    acc[item.status].push(item);
    return acc;
  }, {});

  const walletHistory = (data, tabStatus) => {
    return (
      <>
        {data?.length == 0 || data?.length == undefined ? (
          <div className='El-walletNotfound'>
            <Typography variant='p' gutterBottom>
              No Transactions <br /> Book your stay to earn Elicash
            </Typography>
          </div>
        ) : (
          data?.map((item, index) => (
            <div key={index}>
              <MyWalletItems data={item} tabStatus={tabStatus} />
            </div>
          ))
        )}
      </>
    );
  };

  return (
    <Box sx={{ p: 0, width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='All' {...a11yProps(0)} />
          <Tab label='Unlocking' {...a11yProps(1)} />
          <Tab label='Expiring' {...a11yProps(2)} />
          <Tab label='Expired' {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel
        className='EL-WalletTabPannel'
        sx={{ p: 0, width: '100%' }}
        value={value}
        index={0}
      >
        {walletHistory(walletList, 'all')}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {walletHistory(groupedWalletHistory?.unlocking, 'unlockingTab')}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        {walletHistory(groupedWalletHistory?.expiring, 'expiringTab')}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {walletHistory(groupedWalletHistory?.expired, 'expiredTab')}
      </CustomTabPanel>
    </Box>
  );
}
