import { MyAccountComponent } from '@components/Account';
import Header from '@components/Header';
import HeaderMobile from '@components/Header/Mobile/HeaderMobile';
import { useAccessTokens } from '@customhooks/useTokens';
import { useMediaQuery, useTheme } from '@mui/material';
import { getProfileDetail } from '@reducers/profileReducer';
import HelmetConfig from '@seo/HelmetConfig';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export default function MyAccount(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {} = props;
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAccessTokens();

  useEffect(() => {
    const fetchData = async () => {
      if (accessToken && refreshToken) {
        await dispatch(getProfileDetail());
      }
    };

    fetchData();
  }, [accessToken, refreshToken, dispatch]); // Make sure to include the dependencies

  return (
    <>
      <HelmetConfig
        title='ELIVAAS | My Account'
        description='My Account'
        keywords='My Account'
      />
      {isMobile ? (
        <HeaderMobile
          logoColor={'black'}
          sticky={true}
          transparentHeader={false}
        />
      ) : (
        <Header
          sticky={isMobile ? false : true}
          fixed={isMobile ? false : true}
          logoColor={'black'}
          className={'el-header el-detailHeader'}
        />
      )}
      <MyAccountComponent />
    </>
  );
}
