import { Chip } from '@mui/material';
import Svg from '@components/Common/Svg';
import { Elicash } from '@assets';
import { useSelector } from 'react-redux';
import React from 'react';

const ElicashChip = (props) => {
  const { className, label, variant } = props;

  const { getWalletDetail } = useSelector((state) => state.wallet);

  return (
    <Chip
      className={className}
      size='small'
      avatar={
        <Svg src={Elicash} className='EL-elicashMenuIcon' alt='ELIVAAS' />
      }
      label={label ? label : getWalletDetail?.total_cashback}
      variant={variant}
    />
  );
};

export default ElicashChip;
