import { Avatar, Chip } from '@mui/material';
import React from 'react';
import './amenity.scss';

const Amenity = (props) => {
  const { data, className } = props;
  return (
    <Chip
      avatar={
        <Avatar alt={data?.name} src={data?.icon_url || data?.image_url} />
      }
      label={(data?.value || '') + ' ' + data?.name}
      variant='outlined'
      className={className ? `${className} amenity` : 'amenity'}
    />
  );
};

export default Amenity;
