import { useState } from 'react';
import { useSelector } from 'react-redux';

const useListing = () => {
  const { isScroll } = useSelector((state) => state.glob);
  const [formData, setFormData] = useState({
    phoneNumber: '',
    checkInDate: '',
    checkOutDate: '',
    city: '',
    numberOfGuests: '',
    numberOfChildren: '',
  });

  return {
    isScroll,
    formData,
    setFormData,
  };
};

export default useListing;
