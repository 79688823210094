import BookingFailed from '@components/BookingStatus/BookingCancelled';
import Header from '@components/Header';
import HeaderMobile from '@components/Header/Mobile/HeaderMobile';
import { useMediaQuery, useTheme } from '@mui/material';
import { getConfirmationDetails } from '@reducers/bookingReducer';
import HelmetConfig from '@seo/HelmetConfig';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const BookingConfirmed = (props) => {
  const {} = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const searchParams = new URLSearchParams(location?.search);
  //-----------------OLD ISTAMOJO REDIRECTION URL ------------------------
  //const paymentRequestId = searchParams.get('payment_request_id');

  //-----------------NEW CASHFREE REDIRECTION URL-----------------------
  const paymentRequestId = searchParams.get('payment_gateway_code');

  const { confirmationDetails } = useSelector((state) => state.book);

  useEffect(() => {
    dispatch(getConfirmationDetails(paymentRequestId));
  }, []);

  return (
    <>
      <HelmetConfig
        title='ELIVAAS | BookingConfirmation'
        description='ELIVAAS | BookingConfirmation'
        keywords='Booking, page, keywords'
      />
      {isMobile ? (
        <HeaderMobile
          logoColor={'black'}
          sticky={true}
          transparentHeader={false}
        />
      ) : (
        <Header
          sticky={isMobile ? false : true}
          fixed={isMobile ? false : true}
          logoColor={'black'}
          className={'el-header el-detailHeader'}
        />
      )}
      <BookingFailed confirmationDetails={confirmationDetails} />
    </>
  );
};

export default BookingConfirmed;
