import { Container, useMediaQuery, Divider } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import React from 'react';
import './couponSkeleton.scss';
import { mt } from 'date-fns/locale';
const CouponSkeleton = (props) => {
  const { listLength, type } = props;
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <div
      className={
        type == 'grid' ? 'EL-CouponCardInnerGrid' : 'EL-CouponCardInnerList'
      }
    >
      {Array.from({ length: listLength }, (_, index) => (
        <div
          key={`couponSkeleton${index}`}
          className='EL-CouponCardInnerCoupon'
        >
          <div className='slekton-flex'>
            <Skeleton variant='circular' width={47} height={40} />
            <Skeleton component='h1' variant='text' height={40} />
          </div>
          <div className='slekton-flex'>
            <Skeleton component='h1' variant='text' height={48} width={79} />
          </div>
          <Divider sx={{ mb: 2 }} />
          <Skeleton component='div' variant='text' height={16} />
          <Skeleton component='div' variant='text' height={16} width={240} />
          <br />

          <Skeleton component='div' variant='text' height={20} width={80} />
          {/* {Array.from({ length: 2 }, (_, index) => (
      <Skeleton key={index} component='div' variant='text' height={20} />
    ))} */}
        </div>
      ))}
    </div>
  );
};

export default CouponSkeleton;
