import { Detail } from '@components/DetailPage/Detail';
import Header from '@components/Header';
import HeaderMobile from '@components/Header/Mobile/HeaderMobile';
import DetailSkeleton from '@components/Skeleton/DetailSkeleton';
import { useMediaQuery, useTheme } from '@mui/material';
import { getReviewBookingDetails } from '@reducers/bookingReducer';
import {
  checkAvailabilityLoader,
  loadingImage,
} from '@reducers/globalReducers';
import {
  fetchPropertyDetail,
  fetchingDatesAvailability,
} from '@reducers/propertyReducer';
import HelmetConfig from '@seo/HelmetConfig';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const DetailPage = () => {
  const dispatch = useDispatch();
  const urlLocation = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const urlParts = urlLocation?.pathname.split('/');
  const searchParams = new URLSearchParams(location?.search);
  const bookingId = searchParams && searchParams.get('bookingId');
  const id = parseInt(searchParams.get('propertyId'));
  const slug = urlParts.length > 1 && urlParts[2];
  const cityName = urlParts[1];
  if (!cityName.includes('villas-in')) {
    return <Navigate replace to='/404' />;
  }

  const propertyDetail = useSelector(
    (state) => state?.property?.propertyDetail
  );
  const [isFetched, setIsFetched] = useState(false);
  dispatch(checkAvailabilityLoader(!isFetched));
  dispatch(loadingImage(!isFetched));
  useEffect(() => {
    if (bookingId) {
      dispatch(getReviewBookingDetails(bookingId));
    }
  }, [bookingId]);

  useEffect(() => {
    if (id || propertyDetail?.property_id) {
      dispatch(fetchingDatesAvailability(id || propertyDetail?.property_id))
        .then(() => {
          setIsFetched(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id, propertyDetail?.property_id]);

  useEffect(() => {
    if (slug || id) {
      dispatch(fetchPropertyDetail(slug || id));
    }
  }, [id, slug]);

  return (
    <>
      <HelmetConfig
        title={propertyDetail?.MetaTags?.title}
        description={propertyDetail?.MetaTags?.description}
        keywords={propertyDetail?.MetaTags?.keywords}
      />
      {isMobile ? (
        <HeaderMobile
          logoColor={'black'}
          sticky={true}
          transparentHeader={false}
        />
      ) : (
        <Header
          sticky={isMobile ? false : true}
          fixed={isMobile ? false : true}
          logoColor={'black'}
          className={'el-header el-detailHeader'}
        />
      )}
      {Object.keys(propertyDetail).length > 0 ? (
        <Detail dataJson={propertyDetail} bookingId={bookingId} />
      ) : (
        <DetailSkeleton />
      )}
    </>
  );
};

export default DetailPage;
