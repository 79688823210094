import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Svg from '@components/Common/Svg';
import {
  SilverIcon,
  PlatinumIcon,
  GoldIcon,
  ExclusiveIcon,
  MemberBg,
} from '@assets';

const marks = [
  {
    value: 5000,
    label: 'Silver 5000',
  },
  {
    value: 100000,
    label: 'Gold 100000',
  },
  {
    value: 200000,
    label: 'Platinum 200000',
  },
  {
    value: 295000,
    label: 'Exclusive 295000',
  },
];

function valuetext(value) {
  return `${value}°C`;
}

export default function DiscreteSliderValues() {
  return (
    <Box>
      <Slider
        disableSwap
        slots={{
          Thumb: (
            <Svg
              src={SilverIcon}
              className='EL-memberCardIcon'
              alt='Gold membership'
            />
          ),
        }}
        aria-label='Restricted values'
        defaultValue={200}
        getAriaValueText={valuetext}
        step={null}
        value={40000}
        valueLabelDisplay='auto'
        marks={marks}
        max={300000}
        min={0}
      />
    </Box>
  );
}
