import React, { useState } from 'react';

// Import material
import { Grid, useMediaQuery } from '@mui/material';

// Import  utils
import { ListingSkeleton } from '@utils/skeleton';

// Import  components
import PropetyItem from './item';

// Import  styles
import './property.scss';

const PropetySlider = (props) => {
  const { data, itemCount } = props;
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <>
      <div id='propertySliderInner' className='propertySliderInner'>
        {isMobile ? (
          <>
            <div className='ElPropertyMobile'>
              <Grid container spacing={3}>
                {data?.properties ? (
                  <PropetyItem data={data?.properties.slice(0, itemCount)} />
                ) : (
                  Array.from(
                    { length: itemCount ? itemCount : 2 },
                    (_, index) => (
                      <Grid
                        key={`propertySkeleton${index}`}
                        item
                        xs={12}
                        sm={4}
                        md={4}
                      >
                        <div
                          key={`properties${index}`}
                          className='slekton-flex full-width HomePropertySkeleton'
                        >
                          <ListingSkeleton
                            mobileView={isMobile}
                            key={`property${index}`}
                          />
                        </div>
                      </Grid>
                    )
                  )
                )}
              </Grid>
            </div>
          </>
        ) : (
          <>
            <div className='ElPropertyWeb El-expand'>
              {data?.properties ? (
                <Grid container spacing={5}>
                  <PropetyItem data={data?.properties.slice(0, itemCount)} />
                </Grid>
              ) : (
                <Grid
                  className='HomePropertySkeleton slekton-item slekton-flex'
                  container
                  spacing={4}
                >
                  {Array.from(
                    { length: itemCount ? itemCount : 6 },
                    (_, index) => (
                      <Grid
                        key={`propertySkeleton${index}`}
                        item
                        xs={12}
                        sm={4}
                        md={4}
                      >
                        <ListingSkeleton
                          mobileView={isMobile}
                          key={`propertySkeleton${index}`}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PropetySlider;
