import {
  fetchingLoggedIn,
  firstTime,
  gettingLoggedInData,
  isOtpModal,
} from '@actions/authAction';
import { LogoMini, SignupSuccessJson } from '@assets';
import LottieComponent from '@components/Common/Lottie';
import LoginForm from '@components/Login/LoginForm';
import ProfileForm from '@components/Login/ProfileForm';
import useAuth from '@customhooks/useAuth';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import { getProfileDetail } from '@reducers/profileReducer';
import { windowReload } from '@utils/common';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import './login.scss';
import { useSelector } from 'react-redux';
import { useAccessTokens } from '@customhooks/useTokens';

const cookies = new Cookies();

const Login = (props) => {
  const theme = useTheme();
  const location = useLocation();
  const { accessToken, refreshToken } = useAccessTokens();

  const dispatch = useDispatch();
  const { backtoLogin, setBacktoLogin, isFirstTimeUser } = useAuth();
  const [isNewUser, setNewUser] = useState(false);
  const [loginDisabled, setLoginDisabled] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [signupSuccess, setSignupSuccess] = useState(false);
  const { isOtpModalOpen } = useSelector((state) => state.auth);

  const { open, setOpen, validateOtpRes } = props;

  const handleClose = async () => {
    if (isFirstTimeUser) {
      if (accessToken && refreshToken) {
        dispatch(getProfileDetail());
        dispatch(firstTime(false));
        windowReload();
      }
    } else {
      dispatch(gettingLoggedInData({}));
    }
    dispatch(isOtpModal(false));
    dispatch(setOpen(false));
    dispatch(fetchingLoggedIn(false));
    // windowReload();
  };

  useEffect(() => {
    !isFirstTimeUser &&
      location.pathname == '/booking-detail' &&
      dispatch(setOpen(false));
  }, [isFirstTimeUser]);

  const backToLogin = () => {
    dispatch(fetchingLoggedIn(false));
    setBacktoLogin(true);
    setLoginDisabled(false);
    dispatch(isOtpModal(false));
  };

  useEffect(() => {
    isFirstTimeUser && location.pathname === '/booking' && handleClose();
  }, [isFirstTimeUser]);

  useEffect(() => {
    if (signupSuccess) {
      setTimeout(() => {
        handleClose();
      }, 3000);
    }
  }, [signupSuccess]);

  const returnSuccess = () => {
    return (
      <div className='El-signupSuccess'>
        <Typography variant='h5' component='div'>
          We’re good to go!
        </Typography>
        <LottieComponent
          animationData={SignupSuccessJson}
          loop={true}
          play={true}
          speed={1}
          height={100}
          width={100}
          className='El-successLottie'
        />
        <Typography variant='subtitle1' component='p'>
          {' '}
          You can start booking your favourite vacation home
        </Typography>
      </div>
    );
  };

  return (
    <div>
      {!isMobile ? (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='mobile-otp-login-modal'
          fullWidth
          className='el-loginModal'
          maxWidth={'sm'}
        >
          <DialogTitle id='mobile-otp-login-modal'>
            <div className='el-loginHeader'>
              <span>
                {isOtpModalOpen && (
                  <IconButton
                    edge='end'
                    color='inherit'
                    className='el-loginClose'
                    aria-label='close'
                    onClick={() => backToLogin()}
                  >
                    <ArrowBackIosNewRoundedIcon fontSize='medium' />
                  </IconButton>
                )}
              </span>
              <IconButton
                edge='end'
                color='inherit'
                onClick={handleClose}
                className='el-loginClose'
                aria-label='close'
              >
                <CloseRoundedIcon fontSize='medium' />
              </IconButton>
            </div>
          </DialogTitle>

          <DialogContent sx={{ pl: 5, pr: 5 }} className='el-loginContent'>
            {!signupSuccess ? (
              <>
                <Typography
                  className={
                    isFirstTimeUser ? 'el-signupTitle' : 'el-loginTitle'
                  }
                  variant='h5'
                  component='div'
                >
                  {!isFirstTimeUser ? 'Welcome to ELIVAAS' : 'Create Account'}
                  <Typography variant='subtitle1' component='p'>
                    {isFirstTimeUser &&
                      'Please enter your details to proceed (Optional)'}
                  </Typography>
                </Typography>

                {!isFirstTimeUser ? (
                  <LoginForm
                    backtoLogin={backtoLogin}
                    setBacktoLogin={setBacktoLogin}
                    setOpen={setOpen}
                    setNewUser={setNewUser}
                    isFirstTimeUser={isFirstTimeUser}
                    setLoginDisabled={setLoginDisabled}
                    loginDisabled={loginDisabled}
                  />
                ) : (
                  <ProfileForm
                    setSignupSuccess={setSignupSuccess}
                    handleClose={handleClose}
                  />
                )}
              </>
            ) : (
              returnSuccess()
            )}
          </DialogContent>
          {/* {!signupSuccess && (
            <DialogActions sx={{ mt: 2, mb: 4 }} className='el-loginAction'>
              By proceeding you agree to our{' '}
              <Link href='#'> Privacy Policy </Link>, Agreement and{' '}
              <Link href='#'>T&Cs</Link>.
            </DialogActions>
          )} */}
        </Dialog>
      ) : (
        <Drawer
          hideBackdrop={false}
          anchor='bottom'
          open={open}
          onClose={handleClose}
          className='loginMobileModal'
        >
          <div className='el-loginHeader'>
            <IconButton
              edge='end'
              color='inherit'
              onClick={handleClose}
              className='el-loginClose'
              aria-label='close'
            >
              <CloseRoundedIcon fontSize='medium' />
            </IconButton>
          </div>

          <DialogContent sx={{ pl: 5, pr: 5 }} className='el-loginContent'>
            {!signupSuccess ? (
              <>
                <Typography
                  className={
                    isFirstTimeUser ? 'el-signupTitle' : 'el-loginTitle'
                  }
                  variant='h5'
                  component='div'
                >
                  {!isFirstTimeUser ? 'Welcome to ELIVAAS' : 'Create Account'}
                  <Typography variant='subtitle1' component='p'>
                    {isFirstTimeUser &&
                      'Please enter your details to proceed (Optional)'}
                  </Typography>
                </Typography>

                {!isFirstTimeUser ? (
                  <LoginForm
                    backtoLogin={backtoLogin}
                    setBacktoLogin={setBacktoLogin}
                    setOpen={setOpen}
                    setNewUser={setNewUser}
                    isFirstTimeUser={isFirstTimeUser}
                    setLoginDisabled={setLoginDisabled}
                    loginDisabled={loginDisabled}
                  />
                ) : (
                  /* profile form */
                  <ProfileForm
                    setSignupSuccess={setSignupSuccess}
                    handleClose={handleClose}
                  />
                )}
              </>
            ) : (
              returnSuccess()
            )}
          </DialogContent>
        </Drawer>
      )}
    </div>
  );
};

export default Login;
