import useBookingDetails from '@customhooks/useBookingDetails';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import {
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import GuestOpen from '@components/CheckAvailability/AddGuestList';
import DatePickerDrawer from '@components/CheckAvailability/DatepickerOption/DatePickerDrawer';
import DatePickerModal from '@components/CheckAvailability/DatepickerOption/DatePickerModal';
import {
  formatQueryParam,
  saveFilterValues,
  windowReload,
} from '@utils/common';
import dayjs from 'dayjs';
import useAnalytics from '@components/Common/Analytics';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SelectCity from '../SelectCity';
import './propertyFilterContent.scss';

const PropertyFilterContent = (props) => {
  const { trackEvent } = useAnalytics();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    numberOfGuests,
    setNumberOfGuests,
    numberOfChildren,
    setNumberOfChildren,
    setCheckInDate,
    setCheckOutDate,
    checkInDate,
    checkOutDate,
    availabilityCalenderList,
    isCheckInDatePickerOpen,
    firstDate,
    setCalenderModalError,
    calenderModalError,
    setIsCheckInDatePickerOpen,
    setIsModifyClicked,
    isModifyClicked,
    handleCheckInDateClick,
    pathname,
  } = useBookingDetails();

  const { utmCampaign, utmSource } = useSelector((state) => state.glob);
  const [isGuestsOpen, setIsGuestsOpen] = useState(false);
  const [clearDatesModal, setIsClearDatesModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOuterCliked, setIsOuterClicked] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [isSearchModified, setIsSearchModified] = useState(false);

  const {
    pageType,
    isDrawerOpen,
    setIsDrawerOpen,
    title,
    formData = null,
    setFormData = null,
    storedSearchValues,
    buttonLabel,
  } = props;
  const displayedButtonLabel = storedSearchValues ? buttonLabel : 'Search';

  const handleGuestsOpen = (event) => {
    setIsGuestsOpen(!isGuestsOpen);
  };
  const handleGuestClose = () => {
    setIsGuestsOpen(false);
  };

  const handleDatePickerModal = () => {
    setIsCheckInDatePickerOpen(false);
    setIsMenuOpen(true);
    setIsModifyClicked(false);
  };

  const handleSearch = () => {
    const filterValues = {
      selectedCity,
      checkInDate,
      checkOutDate,
      numberOfGuests,
      numberOfChildren,
    };

    saveFilterValues(filterValues);

    if (isDrawerOpen) {
      setIsDrawerOpen(false);
    }

    const queryParams = [
      formatQueryParam(
        'checkin',
        checkInDate &&
          dayjs(checkInDate, { format: 'DD MMM, YYYY' }).format('YYYY-MM-DD')
      ),
      formatQueryParam(
        'checkout',
        checkOutDate &&
          dayjs(checkOutDate, { format: 'DD MMM, YYYY' }).format('YYYY-MM-DD')
      ),
      formatQueryParam('adults', numberOfGuests),
      formatQueryParam('children', numberOfChildren),
    ].filter(Boolean);
    const queryString =
      queryParams.length > 0 ? `?${queryParams.join('&')}` : '';

    const formattedCityName = selectedCity.replace(/ /g, '-');
    if (isSearchModified) {
      if (pageType === 'listing' || buttonLabel == 'Modify Search') {
        navigate(`/villas${'/villas-in-' + formattedCityName}${queryString}`, {
          replace: true,
        });
        windowReload();
      } else if (pageType === 'home') {
        navigate(`/villas${'/villas-in-' + formattedCityName}${queryString}`);
      }
    }

    // track event
    trackEvent('Property Searched', {
      city: selectedCity,
      checkInDate: checkInDate,
      checkOutDate: checkOutDate,
      numberOfGuests: numberOfGuests,
      numberOfChildren: numberOfChildren,
      totalGuest: numberOfGuests + numberOfChildren,
      utmCampaign: utmCampaign,
      utmSource: utmSource,
    });
  };

  const isSearchDisabled = !(
    selectedCity !== '' ||
    (checkInDate && checkOutDate)
  );

  useEffect(() => {
    if (storedSearchValues) {
      setSelectedCity(storedSearchValues.city);
      setCheckInDate(storedSearchValues.checkInDate);
      setCheckOutDate(storedSearchValues.checkOutDate);
      setNumberOfGuests(storedSearchValues.numberOfGuests);
      setNumberOfChildren(storedSearchValues.numberOfChildren);
    }
  }, [storedSearchValues]);

  useEffect(() => {
    if (pageType === 'callback') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        checkInDate: checkInDate,
        checkOutDate: checkOutDate,
        city: selectedCity,
        numberOfGuests: numberOfGuests,
        numberOfChildren: numberOfChildren,
      }));
    }
  }, [
    checkInDate,
    checkOutDate,
    selectedCity,
    numberOfGuests,
    numberOfChildren,
  ]);
  useEffect(() => {
    const newFilterValues = {
      selectedCity,
      checkInDate,
      checkOutDate,
      numberOfGuests,
      numberOfChildren,
    };

    const storedFilterValues = localStorage.getItem('filterValues');
    const previousFilterValues = storedFilterValues
      ? JSON.parse(storedFilterValues)
      : null;

    const hasChanges =
      !previousFilterValues ||
      Object.keys(newFilterValues).some(
        (key) => newFilterValues[key] !== previousFilterValues[key]
      );

    setIsSearchModified(hasChanges);
  }, [
    selectedCity,
    checkInDate,
    checkOutDate,
    numberOfGuests,
    numberOfChildren,
  ]);
  const handleRefresh = () => {
    // track event
    trackEvent('Property Searched', {
      city: selectedCity,
      checkInDate: checkInDate,
      checkOutDate: checkOutDate,
      numberOfAdults: numberOfGuests,
      numberOfChildren: numberOfChildren,
      totalGuest: numberOfGuests + numberOfChildren,
      utmCampaign: utmCampaign,
      utmSource: utmSource,
    });
    window.location.reload();
  };

  return (
    <>
      <Box
        className={`El-content-container ${
          pageType === 'listing'
            ? 'listing'
            : pageType == 'callback'
            ? 'callback'
            : ''
        }`}
      >
        {(pageType == 'home' ||
          (pageType == 'listing' && isMobile) ||
          !pageType) && (
          <div className='content-header'>
            {isMobile && (
              <ArrowBackIcon onClick={() => setIsDrawerOpen(false)} />
            )}
            {(pageType == 'home' ||
              (pageType == 'listing' && isMobile) ||
              !pageType) && (
              <Typography component='p' variant='p'>
                {title}
              </Typography>
            )}
          </div>
        )}

        <div
          className={`el-fields-container ${
            pageType == 'callback' && 'callback'
          }`}
        >
          <div className='el-cityList-container'>
            <FormControl fullWidth>
              <SelectCity
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
              />
            </FormControl>
          </div>

          <div className='el-datefields-container'>
            <div
              className='CheckinCheckoutField'
              onClick={handleCheckInDateClick}
              id={
                pageType == 'callback'
                  ? 'detailCheckinCheckoutCallback'
                  : 'detailCheckinCheckout'
              }
            >
              <TextField
                // disabled={showAvailabilty}
                // placeholder='Check-out'
                // InputLabelProps={{ shrink: false }}
                variant='filled'
                value={checkInDate ? checkInDate : ''}
                label={
                  <div className='El-calLabel'>
                    <CalendarMonthOutlinedIcon />
                    Check-In
                  </div>
                }
                fullWidth
                readOnly
                focused={false}
                InputProps={{ autoComplete: 'off', readOnly: true }}
              />
            </div>

            <div
              className='CheckinCheckoutField'
              onClick={handleCheckInDateClick}
              id={
                pageType == 'callback'
                  ? 'detailCheckinCheckoutCallback'
                  : 'detailCheckinCheckout'
              }
            >
              <TextField
                // disabled={showAvailabilty}
                // placeholder='Check-out'
                // InputLabelProps={{ shrink: false }}
                variant='filled'
                value={checkInDate !== checkOutDate ? checkOutDate : ''}
                label={
                  <div className='El-calLabel'>
                    <CalendarMonthOutlinedIcon />
                    Check-Out
                  </div>
                }
                fullWidth
                readOnly
                focused={false}
                InputProps={{ autoComplete: 'off', readOnly: true }}
              />
            </div>
          </div>

          <div className='el-guestButton-container'>
            <Button
              className='El-addGuestButton'
              fullWidth
              size='large'
              onClick={handleGuestsOpen}
              variant='text'
              color='secondary'
              id={
                pageType == 'callback'
                  ? 'number-of-guests-button-callback'
                  : 'number-of-guests-button'
              }
            >
              <div className='El-addGuestButtonInner'>
                <PeopleAltOutlinedIcon />
                {numberOfGuests === ''
                  ? 'Number of Guests'
                  : `${numberOfGuests} Adult(s), ${numberOfChildren} Kid(s)`}
              </div>
            </Button>
            <GuestOpen
              handleGuestClose={handleGuestClose}
              isOpen={isGuestsOpen}
              numberOfGuests={numberOfGuests}
              numberOfChildren={numberOfChildren}
              setNumberOfChildren={setNumberOfChildren}
              setNumberOfGuests={setNumberOfGuests}
              standard_guests={3}
              max_children={10}
              handleUpdatingPricing={handleGuestClose}
              max_adults={30}
            />
          </div>

          <div className='el-search-container'>
            <FormControl fullWidth>
              <Button
                fullWidth
                variant='contained'
                onClick={isSearchModified ? handleSearch : handleRefresh}
                disableElevation
                className='el-search-btn'
                disabled={(!isSearchModified && isMobile) || isSearchDisabled}
              >
                {!isSearchModified && !isMobile
                  ? 'Refresh'
                  : displayedButtonLabel}
              </Button>
            </FormControl>
          </div>
        </div>
      </Box>

      {isMobile ? (
        <DatePickerDrawer
          open={isCheckInDatePickerOpen}
          setCheckInDate={setCheckInDate}
          setCheckOutDate={setCheckOutDate}
          isDrawer={false}
          setIsClearDatesModal={setIsClearDatesModal}
          checkInDate={checkInDate}
          checkOutDate={checkOutDate}
          firstDate={firstDate}
          availabilityCalenderList={availabilityCalenderList}
          calenderModalError={calenderModalError}
          setCalenderModalError={setCalenderModalError}
          setIsCheckInDatePickerOpen={setIsCheckInDatePickerOpen}
          isCheckInDatePickerOpen={isCheckInDatePickerOpen}
          clearDatesModal={clearDatesModal}
          setGuestDrawer={setIsMenuOpen}
          isModifyClicked={isModifyClicked}
          handleDatePickerModal={handleDatePickerModal}
          setIsModifyClicked={setIsModifyClicked}
          setIsOuterClicked={setIsOuterClicked}
        />
      ) : (
        <DatePickerModal
          open={isCheckInDatePickerOpen}
          onClose={() => setIsCheckInDatePickerOpen(false)}
          setCheckInDate={setCheckInDate}
          setCheckOutDate={setCheckOutDate}
          isDrawer={false}
          setIsClearDatesModal={setIsClearDatesModal}
          checkInDate={checkInDate}
          checkOutDate={checkOutDate}
          firstDate={firstDate}
          availabilityCalenderList={availabilityCalenderList}
          calenderModalError={calenderModalError}
          setCalenderModalError={setCalenderModalError}
          handleDatePickerModal={handleDatePickerModal}
          isModifyClicked={isModifyClicked}
          setIsModifyClicked={setIsModifyClicked}
          clearDatesModal={clearDatesModal}
        />
      )}
    </>
  );
};

export default PropertyFilterContent;
