import { combineReducers } from 'redux';
import counterReducer from '@reducers/counterReducer';
import propertyListReducer from '@reducers/propertyReducer';
import globalReducer from '@reducers/globalReducers';
import bookingReducer from '@reducers/bookingReducer';
// import userReducer from './userReducer';
import profileReducer from '@reducers/profileReducer';
import authReducer from '@reducers/authReducer';
import couponReducer from '@reducers/couponReducer';
import walletReducer from '@reducers/walletReducer';

const rootReducer = combineReducers({
  counter: counterReducer,
  property: propertyListReducer,
  glob: globalReducer,
  book: bookingReducer,
  wallet: walletReducer,

  //   user: userReducer,
  profile: profileReducer,
  auth: authReducer,
  coupon: couponReducer,
});

export default rootReducer;
