import { useState } from 'react';
import { useSelector } from 'react-redux';

const useAuth = () => {
  const { isFirstTimeUser, validateOtpRes, otpErrorRes } = useSelector(
    (state) => state.auth
  );

  const [open, setOpen] = useState(false);
  const [backtoLogin, setBacktoLogin] = useState(false);

  return {
    open,
    setOpen,
    backtoLogin,
    setBacktoLogin,
    isFirstTimeUser,
    validateOtpRes,
    otpErrorRes,
  };
};

export default useAuth;
