import { NoResults, PropertyListBanner } from '@assets';
import CallBackRequest from '@components/CallBack';
import MobileHeader from '@components/Common/Mobile/Header';
import BackToTop from '@components/Common/ScrollTop';
import PropetySlider from '@components/Home/Property';
import CallIcon from '@mui/icons-material/Call';
import PropertyFilterBar from '@components/PropertyFilterBar';
import useListing from '@customhooks/useListing';
import { ArrowBack } from '@mui/icons-material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useAnalytics from '@components/Common/Analytics';
import { contact } from '@utils/customText';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { scrollingListing } from '@reducers/globalReducers';
import {
  getCityNameFromPath,
  windowReload,
  isCityNameValid,
  getValidCityName,
} from '@utils/common';
import { ListingSkeleton } from '@utils/skeleton';
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import './propertyList.scss';
import { DangerousHtml } from '@components/Common/DangerousHtml/Index';

const PropertyList = (props) => {
  const { trackEvent } = useAnalytics();
  const dispatch = useDispatch();
  const theme = useTheme();
  const location = useLocation();
  const { utmCampaign, utmSource } = useSelector((state) => state?.glob);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState(false);
  const [isCallbackOpen, setIsCallbackOpen] = useState(false);
  const [isCallbackVisible, setIsCallbackVisible] = useState(true);
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const { propertyList, cityListData } = props;

  const navigate = useNavigate();
  const { isScroll } = useListing();
  const urlPathname = location.pathname;

  const isCityValid = isCityNameValid(
    cityListData,
    getCityNameFromPath(urlPathname)
  );
  const cityName = getValidCityName(
    getCityNameFromPath(urlPathname),
    isCityValid
  );

  const PropertySkeleton = Array.from(
    { length: isMobile ? 1 : 3 },
    (_, index) => (
      <ListingSkeleton mobileView={isMobile} key={`propertyList${index}`} />
    )
  );

  const totalProperties = propertyList ? propertyList.properties.length : 0;

  const resultsHeader =
    totalProperties > 0
      ? totalProperties === 1
        ? `${totalProperties} Property`
        : `${totalProperties} Properties`
      : '';

  const scrollThreshold = !isMobile ? 520 : 120;

  const openCallbackRequest = () => {
    setIsCallbackOpen(true);
  };

  const handleClearSelection = () => {
    localStorage.removeItem('filterValues');
    navigate('/villas', { replace: true });
    windowReload();
  };

  const onBackButtonClick = () => window.history.back();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [propertyList]);

  // Property list Page Viewed event trigger
  useEffect(() => {
    const parsedFilterValues = JSON.parse(localStorage.getItem('filterValues'));
    let timeoutId;
    if (propertyList) {
      timeoutId = setTimeout(() => {
        trackEvent('Listings Page Viewed', {
          city: parsedFilterValues?.selectedCity,
          checkInDate: parsedFilterValues?.checkInDate,
          checkOutDate: parsedFilterValues?.checkOutDate,
          numberOfGuests: parsedFilterValues?.numberOfGuests,
          numberOfChildren: parsedFilterValues?.numberOfChildren,
          number_of_properties: propertyList?.properties?.length,
          utmCampaign: utmCampaign,
          utmSource: utmSource,
        });
      }, 3000);
    }

    // Clean up the timeout
    return () => {
      clearTimeout(timeoutId);
    };
  }, [propertyList]); // Run the effect only when the title prop changes

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrollHeight = window.scrollY;
      if (scrollHeight >= scrollThreshold) {
        dispatch(scrollingListing(true));
      } else {
        dispatch(scrollingListing(false));
      }
    });
  }, []);

  useEffect(() => {
    if (propertyList?.properties?.length <= 0) {
      setIsCallbackVisible(false);
      setIsBannerVisible(false);
    }
  }, [propertyList]);

  return (
    <>
      <>
        {isScroll && (
          <MobileHeader
            onClick={onBackButtonClick}
            showBackButton={isMobile ? true : false}
          >
            <Container
              id='stickyPropertyFilterbar'
              className='mobileHeader-search'
            >
              <PropertyFilterBar
                title='Where do you want to go?'
                pageType={'listing'}
                buttonLabel='Modify Search'
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
              />
            </Container>
          </MobileHeader>
        )}

        {(isBannerVisible || isMobile) && (
          <Container
            id='mobilePropertyListBanner'
            className='mobilePropertyListBanner'
            maxWidth='xl'
            disableGutters
          >
            {isMobile && (
              <div className='cityPageHeader'>
                <IconButton onClick={onBackButtonClick} className='backButton'>
                  <ArrowBack />
                </IconButton>
                <Button
                  className='EL-contactUsSticky'
                  color='secondary'
                  size='small'
                  startIcon={<CallIcon />}
                  disableElevation
                  variant='outlined'
                  href={`tel:${contact.number}`}
                >
                  {contact?.number}
                </Button>
              </div>
            )}

            <img
              src={
                !isMobile
                  ? propertyList?.cityContent?.desktop_url || PropertyListBanner
                  : propertyList?.cityContent?.mobile_url || PropertyListBanner
              }
              className='bannerImage'
              alt='Property'
            />
            {cityName && (
              <Typography
                variant='subtitle1'
                component='h1'
                className='el-bannerTitle'
              >
                Villas in <span>{cityName}</span>
              </Typography>
            )}
          </Container>
        )}

        {isMobile && <BackToTop id='mobilePropertyListBanner' />}
      </>

      <Container
        id='propertyFilterbar'
        className={
          isMobile
            ? 'el-listingFilterBar-mobile'
            : !isBannerVisible
            ? 'el-listingFilterBarWithoutBanner'
            : 'el-listingFilterBar'
        }
      >
        <PropertyFilterBar
          title='Where do you want to go?'
          buttonLabel='Modify Search'
          pageType={!isBannerVisible && 'listing'}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        />

        {isCallbackVisible && (
          <CallBackRequest
            id='EL-propertyList-Callback'
            className={'EL-propertyListCallback'}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
          />
        )}
      </Container>

      <Container
        id='propertyList'
        maxWidth='100%'
        className='el-listingSection'
      >
        <Container className='listingPropertySlider' maxWidth='lg'>
          <Suspense
            fallback={
              <div className=' slekton-flex full-width'>{PropertySkeleton}</div>
            }
          >
            <Typography
              variant='subtitle1'
              component='p'
              className='el-propertiesCount'
            >
              {resultsHeader}
            </Typography>

            <PropetySlider data={propertyList} />
          </Suspense>
        </Container>
      </Container>
      {propertyList?.properties?.length === 0 && (
        <Box className='El-noResults'>
          <img src={NoResults} alt='no result' />

          <Typography variant='h6' className='no-result-h6'>
            No properties available!
          </Typography>

          <Typography variant='caption' className='no-result-caption'>
            Please check for a different location or dates
          </Typography>

          <div className='EL-noResult-action'>
            <Button
              className='el-clear-filter'
              size='large'
              variant='text'
              color='primary'
              id='clear-selection'
              onClick={handleClearSelection}
            >
              Clear Selection
            </Button>
            <Button
              className='el-noResultCallback'
              size='large'
              variant='text'
              color='primary'
              id='clear-selection'
              onClick={openCallbackRequest}
            >
              Request Callback
            </Button>
          </div>
        </Box>
      )}
      {propertyList?.cityContent?.name !== 'DEFAULT' &&
        propertyList?.cityContent?.metadata && (
          <Box className={'El-city-description'}>
            <Accordion defaultExpanded={true} disableGutters square>
              <AccordionSummary
                expandIcon={
                  !isMobile ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <ArrowDropDownOutlinedIcon />
                  )
                }
                aria-controls={'plp-city-content'}
                id={'plp-city-header'}
              >
                <Typography component='h3' variant='h3'>
                  {cityName}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <DangerousHtml data={propertyList?.cityContent?.metadata} />
              </AccordionDetails>
            </Accordion>
          </Box>
        )}
      {isCallbackOpen && (
        <CallBackRequest
          className={'EL-propertyListCallback'}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          isOpenDialog={isCallbackOpen}
          setIsOpenDialog={setIsCallbackOpen}
        />
      )}
    </>
  );
};

export default PropertyList;
