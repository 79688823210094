import {
  Button,
  FormControl,
  Grid,
  Input as InputField,
  InputLabel,
} from '@mui/material';
import { upDateProfileDetail } from '@reducers/profileReducer';
import useAnalytics from '@components/Common/Analytics';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './profileForm.scss';

export const ProfileForm = (props) => {
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const { isLoggedInStatus, validateOtpRes } = useSelector(
    (state) => state.auth
  );

  // prope
  const { setSignupSuccess, handleClose } = props;

  // state
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [errors, setErrors] = useState({});
  const [loginDisabled, setLoginDisabled] = useState(true);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    const errors = validateFields({
      ...formData,
      [name]: value,
    });
    const hasErrors = Object.keys(errors).some((field) => errors[field]);
    setLoginDisabled(hasErrors);
    setErrors(errors);
  };

  const validateFields = (data) => {
    const errors = {};
    const requiredFields = ['firstName', 'email', 'lastName'];
    const fieldLabels = {
      firstName: 'First Name',
      email: 'Email',
      lastName: 'Last Name',
    };

    for (const field of requiredFields) {
      const value = data[field];
      if (!value) {
        errors[field] = `${fieldLabels[field]} is required`;
      } else if (field === 'email') {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (!emailPattern.test(value)) {
          errors[field] = 'Invalid email address';
        }
      }
    }
    return errors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // if (handleValidation()) {
    trackEvent('Optional Info Form Submission', {
      mobile_number: localStorage.getItem('mobile'),
      first_name: formData?.firstName,
      last_name: formData?.lastName,
      email: formData?.email,
    });
    let data = {
      gms_user: {
        user: {
          first_name: formData?.firstName,
          last_name: formData?.lastName,
          email: formData?.email,
        },
      },
    };
    await dispatch(upDateProfileDetail(data));
    await setSignupSuccess(true);
    // }
  };

  // useEffect(() => {
  //   if (Object.keys(validateOtpRes).length > 0) {
  //     setOpen(false);
  //   }
  // }, [validateOtpRes]);

  return (
    <div className='el-loginFieldMain EL-profileForm'>
      <div className='el-profileField'>
        <Grid className='El-ProfileFieldBox' item xs={12}>
          <FormControl variant={'standard'}>
            <InputLabel color='secondary' htmlFor={'input-firstName'}>
              First Name
            </InputLabel>
            <InputField
              name='firstName'
              color='secondary'
              disableUnderline
              fullWidth={true}
              required={true}
              id='El-firstName'
              shrink={true}
              startAdornment={' '}
              value={formData.firstName} // Assign the correct value from formData
              onChange={handleChange}
              //error={errors?.firstName ? true : false}
              placeholder='Enter First Name'
            />
            {/* <Typography
              className='Mui-error Mui-required'
              style={{ color: 'red' }}
            >
              {errors.firstName}
            </Typography> */}
          </FormControl>
          <FormControl variant={'standard'}>
            <InputLabel color='secondary' htmlFor={'input-lastName'}>
              Last Name
            </InputLabel>
            <InputField
              color='secondary'
              disableUnderline
              fullWidth={true}
              required={true}
              id='El-lastName'
              shrink={true}
              startAdornment={' '}
              name='lastName'
              value={formData.lastName} // Assign the correct value from formData
              onChange={handleChange}
              //error={errors?.lastName ? true : false}
              placeholder='Enter Last Name'
            />
            {/* <Typography
              className='Mui-error Mui-required'
              style={{ color: 'red' }}
            >
              {errors.lastName}
            </Typography> */}
          </FormControl>
        </Grid>
        <Grid className='El-ProfileFieldBox' item xs={12}>
          <FormControl fullWidth variant={'standard'}>
            <InputLabel color='secondary' htmlFor={'input-email'}>
              Email
            </InputLabel>
            <InputField
              color='secondary'
              disableUnderline
              fullWidth={true}
              required={true}
              id='El-email'
              shrink={true}
              startAdornment={' '}
              name='email'
              value={formData.email} // Assign the correct value from formData
              onChange={handleChange}
              placeholder='eg. km123@gmail.com'
              //error={errors?.email ? true : false}
              endAdornment
            />
            {/* <Typography
              className='Mui-error Mui-required'
              style={{ color: 'red' }}
            >
              {errors.email}
            </Typography> */}
          </FormControl>
        </Grid>
        <Button
          variant='contained'
          fullWidth
          onClick={handleFormSubmit}
          color='secondary'
          size='large'
          disabled={loginDisabled}
          sx={{ mt: 2 }}
          className='EL-ProfileConfirm'
        >
          Save
        </Button>

        <div className='el_Dit'>
          <Button color='secondary' variant='text' onClick={handleClose}>
            Skip for now
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
