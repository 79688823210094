import { bookingStatus } from '@actions/propertiesAction';
import { DATE_PICKER_HEADERS } from '@utils/constants';
import { dateFormat } from '@utils/customText';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateTheCalender } from '../reducers/propertyReducer';
import { changeCheckoutValue } from '../utils/common';

// Custom hook for CheckinCheckoutDetail and BottomNavigationCAT
const useBookingDetails = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(location?.search);

  const id = searchParams.get('bookingId') ? searchParams.get('bookingId') : '';
  const initialDatePickerHeader = DATE_PICKER_HEADERS.checkInDateHeader;

  const [numberOfGuests, setNumberOfGuests] = useState(1);
  const [numberOfChildren, setNumberOfChildren] = useState(0);
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [isCheckInDatePickerOpen, setIsCheckInDatePickerOpen] = useState(false);
  const [calenderModalError, setCalenderModalError] = useState('');
  const [totalNumberOfNights, setTotalNumberOfNights] = useState(0);
  const [isModifyClicked, setIsModifyClicked] = useState(false);
  const [datePickerHeader, setDatePickerHeader] = useState(
    initialDatePickerHeader
  );
  const checkInQueryParam = encodeURIComponent(
    checkInDate?.replace(/,/g, '')?.replace(/ /g, '')
  );
  const checkOutQueryParam = encodeURIComponent(
    checkOutDate?.replace(/,/g, '')?.replace(/ /g, '')
  );
  const numberOfGuestsQueryParam = encodeURIComponent(numberOfGuests);
  const numberOfChildrenQueryParam = encodeURIComponent(numberOfChildren);

  const handleCheckInDateClick = () => {
    setIsCheckInDatePickerOpen(true);
  };

  const availabilityCalenderList = useSelector(
    (state) => state?.property?.availabilityDates
  );

  const bookingId = useSelector(
    (state) => state?.property?.gettingBookingResponse || id
  );

  const isBookingSuccessStatus = useSelector(
    (state) => state?.property?.isBookingSuccess
  );

  const {
    fetchingBookingDetailsStatus,
    fetchingBookingDetailsRes,
    isPartialPayment,
  } = useSelector((state) => state.book);

  const firstDate =
    availabilityCalenderList && Object.keys(availabilityCalenderList).length > 0
      ? Object.keys(availabilityCalenderList).find(
          (date) => availabilityCalenderList[date] === 1
        )
      : dayjs().format();

  const pathname = location?.pathname;

  useEffect(() => {
    if (location.pathname === '/booking' || location.pathname === '/') {
      const data = fetchingBookingDetailsRes;
      const checkInDate = dayjs(data?.checkin_on);
      const checkOutDate = dayjs(data?.checkout_on);
      setNumberOfGuests(data?.adults || 1);
      setNumberOfChildren(data?.children || 0);
      setTotalNumberOfNights(data?.room_nights_count);
      setCheckInDate(
        location.pathname === '/'
          ? ''
          : dayjs(checkInDate).format(dateFormat.ddmmyy)
      );
      setCheckOutDate(
        location.pathname === '/'
          ? ''
          : dayjs(checkOutDate).format(dateFormat.ddmmyy)
      );
      dispatch(bookingStatus(false));
    }
  }, [location, fetchingBookingDetailsRes, fetchingBookingDetailsStatus]);

  useEffect(() => {
    if (isModifyClicked === true) {
      setIsCheckInDatePickerOpen(true);
    }
  }, [isModifyClicked]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    const bookingId = searchParams.get('bookingId');
    if (
      (location.pathname === '/booking' || bookingId) &&
      isCheckInDatePickerOpen
    ) {
      const data = changeCheckoutValue(
        availabilityCalenderList,
        dayjs(checkOutDate).format('YYYY-MM-DD')
      );
      dispatch(updateTheCalender(data));
    }
  }, [
    location,
    checkInDate,
    availabilityCalenderList,
    isCheckInDatePickerOpen,
  ]);

  return {
    numberOfGuests,
    setNumberOfGuests,
    numberOfChildren,
    setNumberOfChildren,
    checkInDate,
    setCheckInDate,
    checkOutDate,
    setCheckOutDate,
    isCheckInDatePickerOpen,
    setIsCheckInDatePickerOpen,
    checkInQueryParam,
    checkOutQueryParam,
    numberOfGuestsQueryParam,
    numberOfChildrenQueryParam,
    handleCheckInDateClick,
    availabilityCalenderList,
    firstDate,
    setCalenderModalError,
    calenderModalError,
    bookingId,
    isBookingSuccessStatus,
    totalNumberOfNights,
    setTotalNumberOfNights,
    pathname,
    fetchingBookingDetailsRes,
    fetchingBookingDetailsStatus,
    //handleModifyClicked,
    setIsModifyClicked,
    isModifyClicked,
    isPartialPayment,
    datePickerHeader,
    setDatePickerHeader,
  };
};

export default useBookingDetails;
