import { Container, useMediaQuery } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';
import './detailSkeleton.scss';
const DetailSkeleton = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <Container
      maxWidth='lg'
      id='propertyDetailContainer'
      className='el-section propertyDetailContainer'
    >
      <div className='el-detailPageContainer'>
        <div className='slekton-flex'>
          <Skeleton
            variant='rounded'
            width={isMobile ? '78%' : '93%'}
            height={isMobile ? '30vh' : '54vh'}
          />
          {isMobile ? (
            <div>
              <Skeleton variant='rounded' width={'100%'} height={'14.5vh'} />
              <Skeleton variant='rounded' width={'100%'} height={'14.5vh'} />
            </div>
          ) : (
            <>
              <div>
                <Skeleton variant='rounded' width={'100%'} height={'26vh'} />
                <Skeleton variant='rounded' width={'100%'} height={'26vh'} />
              </div>
              <div>
                <Skeleton variant='rounded' width={'100%'} height={'26vh'} />
                <Skeleton variant='rounded' width={'100%'} height={'26vh'} />
              </div>
            </>
          )}
        </div>
        <Skeleton component='h1' variant='text' height={45} />
        <Skeleton component='h3' variant='text' height={20} /> <br />
        <Skeleton component='h3' variant='text' height={20} />
        {Array.from({ length: 4 }, (_, index) => (
          <Skeleton key={index} component='p' variant='text' />
        ))}
        <Skeleton component='div' variant='text' height={96} />{' '}
        <Skeleton component='div' variant='text' height={40} width={150} />
        <Skeleton component='div' variant='text' height={40} />
        {Array.from({ length: 2 }, (_, index) => (
          <Skeleton key={index} component='div' variant='text' height={20} />
        ))}
      </div>
    </Container>
  );
};

export default DetailSkeleton;
