import React from 'react';
import { Container, useMediaQuery, Divider, Grid, Box } from '@mui/material'; // Use Box instead of Item
import Skeleton from '@mui/material/Skeleton';
import Avatar from '@mui/material/Avatar';
import './guestSleleton.scss';

const GuestSkeleton = (props) => {
  const { listLength, type } = props;
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <div className='EL-guestSkeleton'>
      <Skeleton component='h1' width={'100%'} height={24} />
      <br />
      <Skeleton style={{ marginBottom: '8px' }} width={'100%'} height={12} />
      <Skeleton style={{ marginBottom: '8px' }} width={'100%'} height={12} />
      <Skeleton style={{ marginBottom: '8px' }} width={'100%'} height={12} />
      <br />
      <Grid container spacing={2} columns={12}>
        {Array.from({ length: listLength }, (_, index) => (
          <Grid key={`guestSkeleton${index}`} item xs={6} md={4}>
            {/* Replaced Item with Box */}
            <Box>
              <Skeleton width={'100%'} height={isMobile ? 123 : 301} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default GuestSkeleton;
