import {
  FETCHING_PROFILE_DETAILS,
  GETTING_PROFILE_DETAILS,
  UPDATING_PROFILE_DETAILS_STATUS,
  PROFILE_UPLOAD_SUCCESS,
  IS_PROFILE_UPDATED_STATUS,
  fetchingProfile,
  gettingProfile,
  updatingProfile,
  gettingProfilePictureUrl,
  updateProfileStatus,
} from '@actions/profileAction';
import { instance } from '../instance/axios';
import {
  handleErrorResponse,
  recursivelyConvertToNull,
  saveUserDetailsToLocalStorage,
} from '@utils/common';

//-------------CONSTANTS---------------

import {
  checkAvailabilityLoader,
  errorImage,
  loadingImage,
} from './globalReducers';

//---------------------INITIAL STATE-------------------

const initialState = {
  fetchingProfileDetails: false,
  gettingProfileDetails: {},
  updatingProfileStatus: false,
  profileUrl: {},
  isProfileUpdated: false,
};

export const getProfileDetail = () => {
  return async (dispatch) => {
    dispatch(fetchingProfile(true));

    try {
      const response = await instance({
        method: 'GET',
        url: `gateways/guest/class/user/functions/getLoggedInUser`,
      });

      const data = response.data;
      saveUserDetailsToLocalStorage(data);

      dispatch(fetchingProfile(false));
      dispatch(gettingProfile(data));
    } catch (error) {
      handleErrorResponse(error, dispatch);

      // Add error handling here if needed
    }
  };
};

export const upDateProfileDetail = (data) => {
  return (dispatch) => {
    const updatedData = recursivelyConvertToNull(data);
    dispatch(updatingProfile(true));
    instance({
      method: 'PATCH',
      url: `gateways/guest/class/user/functions/updateLoggedInUser`,
      data: updatedData,
    })
      .then((response) => {
        dispatch(updatingProfile(false));
        dispatch(updateProfileStatus(true));
        dispatch(getProfileDetail());
      })
      .catch((error) => {
        handleErrorResponse(error, dispatch);

        // Add error handling here if needed
      });
  };
};

// export const profileUploadOnServer = (imageType) => {
//   return async (dispatch) => {
//     try {
//       const authToken =
//         'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwic2Vzc2lvbl9pZCI6ODg2LCJpYXQiOjE3MDAxMjY0NjQsImV4cCI6MTczNjEyMjg2NH0.70d7vUrxr0uqQmu8UIkoA6mL5nQvgQ0WzxjkjA_2lSg';
//       const config = {
//         headers: {
//           Authorization: `Bearer ${authToken}`,
//         },
//       };
//       instance({
//         method: 'POST',
//         url: `gateways/guest/api/users/me/photos/presignedPutUrl?extension=${imageType.extension}`,
//         data: data,
//       })

//       const data = response.data;
//       return dispatch(gettingProfilePictureUrl(data));
//     } catch (error) {
//       console.log('error', error);
//     }
//   };
// };

//--------------------REDUCER-------------------------
const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_PROFILE_DETAILS:
      return {
        ...state,
        fetchingProfileDetails: action.payload,
      };
    case GETTING_PROFILE_DETAILS:
      return {
        ...state,
        gettingProfileDetails: action.payload,
      };
    case UPDATING_PROFILE_DETAILS_STATUS:
      return {
        ...state,
        updatingProfileStatus: action.payload,
      };
    case PROFILE_UPLOAD_SUCCESS:
      return {
        ...state,
        profileUrl: action.payload,
      };
    case IS_PROFILE_UPDATED_STATUS:
      return {
        ...state,
        isProfileUpdated: action.payload,
      };
    default:
      return state;
  }
};

export default profileReducer;
