import { HomePinSvg } from '@assets';
import Svg from '@components/Common/Svg';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import {
  Drawer,
  FormControlLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { fetchCityList } from '@reducers/propertyReducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './selectCity.scss';

const SelectCity = ({ selectedCity, setSelectedCity }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { cityList } = useSelector((state) => state.property);
  const cityListData = cityList?.data;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setDrawerOpen((prev) => !prev);
  };

  const handleToggleSelectOpen = () => {
    setIsSelectOpen((prev) => !prev);
  };

  const handleDrawerClick = () => {
    setDrawerOpen((prev) => !prev);
  };

  const getFormattedRenderValue = (value) => {
    return value;
  };

  useEffect(() => {
    if (selectedCity) setIsSelectOpen(false);
  }, [selectedCity]);

  useEffect(() => {
    if (!cityListData) {
      dispatch(fetchCityList());
    }
  }, [cityListData]);

  return (
    <>
      {isMobile ? (
        <>
          <TextField
            variant='outlined'
            fullWidth
            value={selectedCity}
            onClick={handleDrawerClick}
            placeholder='Select Location'
            InputProps={{
              startAdornment: selectedCity ? (
                <InputAdornment position='start'>
                  <Svg src={HomePinSvg} alt='HomePin' />
                </InputAdornment>
              ) : (
                <InputAdornment position='start'>
                  <Svg src={HomePinSvg} alt='HomePin' />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position='end'>
                  <KeyboardArrowDownOutlinedIcon />
                </InputAdornment>
              ),
              readOnly: true,
            }}
            focused={false}
          />
          <Drawer
            anchor='bottom'
            open={drawerOpen}
            onClose={handleDrawerClick}
            className='city-drawer'
          >
            <div className='el-drawer-header'>
              <Typography variant='h6'>Select Location</Typography>
              <IconButton
                aria-label='close'
                className='icon-button'
                onClick={handleDrawerClick}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <RadioGroup value={selectedCity} onChange={handleCityChange}>
              <List className='EL-selectCityMenuMobile'>
                {cityListData?.map((city, index) => (
                  <ListItem key={index}>
                    <FormControlLabel
                      value={city}
                      control={<Radio />}
                      label={city}
                    />
                  </ListItem>
                ))}
              </List>
            </RadioGroup>
          </Drawer>
        </>
      ) : (
        <Select
          fullWidth
          labelId='label-select-city'
          id='select-city'
          value={selectedCity}
          onChange={handleCityChange}
          className='citySelect'
          open={isSelectOpen}
          renderValue={getFormattedRenderValue}
          onClick={handleToggleSelectOpen}
          startAdornment={
            selectedCity ? (
              <InputAdornment position='start'>
                <Svg src={HomePinSvg} alt='HomePin' />
              </InputAdornment>
            ) : (
              <InputAdornment position='start'>
                <Svg src={HomePinSvg} alt='HomePin' /> Select Location
              </InputAdornment>
            )
          }
          IconComponent={(props) => (
            <InputAdornment position='end'>
              <KeyboardArrowDownOutlinedIcon {...props} />
            </InputAdornment>
          )}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            // getContentAnchorEl: null,
            PaperProps: {
              style: {
                maxHeight: 300,
                maxWidth: 50,
              },
            },
          }}
        >
          {cityListData?.map((city, index) => (
            <MenuItem
              className='EL-selectCityMenu'
              key={index}
              value={city}
              divider={true}
            >
              <FormControlLabel
                value={city}
                control={
                  <Radio
                    checked={selectedCity === city}
                    sx={{
                      '&, &.Mui-checked': {
                        color: 'black',
                      },
                    }}
                  />
                }
                label={city}
              />
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};

export default SelectCity;
