// generateRoute.js
import React from 'react';
import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import PageWrapper from '@components/layout/PageWrapper';
import MainLayout from '@components/layout/MainLayout';
import appRoutes from './appRoutes';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const generateRoute = (routes) => {
  return (
    <Routes>
      <Route
        path=''
        element={
          <MainLayout>
            <Outlet />
          </MainLayout>
        }
      >
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.private ? (
                localStorage.getItem('accessToken') ? (
                  <PageWrapper state={route.state}>{route.element}</PageWrapper>
                ) : (
                  <Navigate to='/404' />
                )
              ) : (
                <PageWrapper state={route.child ? undefined : route.state}>
                  {route.element}
                </PageWrapper>
              )
            }
          >
            {route.child && generateRoute(route.child)}
          </Route>
        ))}
      </Route>
    </Routes>
  );
};

export default generateRoute;
