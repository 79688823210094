import MyAccount from '@containers/Account';
import MyProfile from '@containers/Account/MyProfile';
import BookingCancelled from '@containers/BookingCancelled';
import WalletContainer from '@containers/Wallet';
import BookingConfirmed from '@containers/BookingConfirmation';
import BookingDetailsContainer from '@containers/BookingDetails';
import BookingInfo from '@containers/BookingInfo';
import DetailPage from '@containers/DetailPage';
import HomePage from '@containers/Home';
import NotFoundPage from '@containers/NotFoundPage';
import PropertyListing from '@containers/PropertyListing';
import React from 'react';

const appRoutes = [
  {
    path: '/',
    element: <HomePage />,
    state: 'home',
    private: false,
  },
  {
    path: '/profile',
    element: <MyProfile />,
    state: 'profile',
    private: true,
  },

  {
    path: '/:city/:property?',
    element: <DetailPage />,
    state: 'detail',
    private: false,
  },
  {
    path: '/booking',
    element: <BookingInfo />,
    state: 'booking',
    private: false,
  },
  {
    path: '/booking/confirmed',
    element: <BookingConfirmed />,
    state: 'booking',
    private: true,
  },
  {
    path: '/booking/cancelled',
    element: <BookingCancelled />,
    state: 'booking',
    private: true,
  },
  {
    path: '/myAccount/:id',
    element: <MyAccount />,
    state: 'booking',
    private: true,
  },
  {
    path: '/booking-detail/:id',
    element: <BookingDetailsContainer />,
    state: 'booking',
    private: false,
  },
  {
    path: '/villas/:city?',
    element: <PropertyListing />,
    state: 'property',
    private: false,
  },
  {
    path: '/404',
    element: <NotFoundPage />,
    state: 'notfound',
    private: false,
  },
  {
    path: '/wallet',
    element: <WalletContainer />,
    state: 'wallet',
    private: false,
  },
  {
    path: '*',
    element: <NotFoundPage />,
    state: 'notfound',
    private: false,
  },
];

export default appRoutes;
