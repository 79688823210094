import {
  GETTING_WALLET_DETAIL_DATA,
  WALLET_DETAIL_LOADING,
  GETTING_WALLET_LIST,
  GETTING_WALLET_LIST_LOADING,
  GETTING_FAQ_LOADING,
  GETTING_FAQ,
  fetchingWalletDetail,
  gettingWalletDetail,
  gettingWalletListLoading,
  gettingWalletList,
  gettingFaqs,
  gettingFaqsLoading,
} from '@actions/walletAction';

import { instance } from '../instance/axios';
import { handleErrorResponse } from '@utils/common';

//---------------------INITIAL STATE-------------------

const initialState = {
  loading: false,
  getWalletDetail: [],
  loading: false,
  gettingWalletListLoading: false,
  getWalletList: [],
  getFaqs: [],
  getFaqsloading: false,
};

// get wallet detail and expiry date
export const getWalletDetailDetails = () => {
  return (dispatch) => {
    dispatch(fetchingWalletDetail(true));
    instance({
      method: 'GET',
      url: 'gateways/guest/class/cashback/functions/getAvailableCashback',
    })
      .then((response) => {
        const data = response.data;
        dispatch(fetchingWalletDetail(false));
        dispatch(gettingWalletDetail(data));
      })
      .catch((error) => {
        handleErrorResponse(error, dispatch);
        dispatch(fetchingWalletDetail(false));

        // Add error handling here if needed
      });
  };
};

// getting wallet transaction list
export const getWalletListing = () => {
  return (dispatch) => {
    dispatch(gettingWalletListLoading(true));
    instance({
      method: 'GET',
      url: 'gateways/guest/class/cashback/functions/getAllCashbackRecords',
    })
      .then((response) => {
        const data = response.data;
        dispatch(gettingWalletListLoading(false));
        dispatch(gettingWalletList(data));
      })
      .catch((error) => {
        handleErrorResponse(error, dispatch);
        dispatch(gettingWalletListLoading(false));

        // Add error handling here if needed
      });
  };
};

// getting wallet transaction list
export const getFaqListing = () => {
  return (dispatch) => {
    dispatch(gettingFaqsLoading(true));
    instance({
      method: 'GET',
      url: 'gateways/guest/class/cashback/getFAQs?category=CASHBACK',
    })
      .then((response) => {
        const data = response.data;
        dispatch(gettingFaqsLoading(false));
        dispatch(gettingFaqs(data));
      })
      .catch((error) => {
        handleErrorResponse(error, dispatch);
        dispatch(gettingFaqsLoading(false));

        // Add error handling here if needed
      });
  };
};

//--------------------REDUCER-------------------------
const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case WALLET_DETAIL_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GETTING_WALLET_DETAIL_DATA:
      return {
        ...state,
        getWalletDetail: action.payload,
      };

    case GETTING_WALLET_LIST_LOADING:
      return {
        ...state,
        gettingWalletListLoading: action.payload,
      };
    case GETTING_WALLET_LIST:
      return {
        ...state,
        getWalletList: action.payload,
      };
    case GETTING_FAQ:
      return {
        ...state,
        getFaqs: action.payload,
      };
    case GETTING_FAQ_LOADING:
      return {
        ...state,
        getFaqsloading: action.payload,
      };
    default:
      return state;
  }
};

export default walletReducer;
