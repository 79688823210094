import BookingInfo from '@components/BookInfo';
import useBookingDetails from '@customhooks/useBookingDetails';
import {
  createBookingRequest,
  getReviewBookingDetails,
  isPartialPaymentToggle,
} from '@reducers/bookingReducer';
import useAnalytics from '@components/Common/Analytics';
import {
  fetchingDatesAvailability,
  fetchPropertyDetail,
} from '@reducers/propertyReducer';
import HelmetConfig from '@seo/HelmetConfig';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { pricingRefactored } from '@utils/common';
import { setOpen, setGuestDetails } from '@reducers/globalReducers';
import Cookies from 'universal-cookie';
import { useAccessTokens } from '@customhooks/useTokens';
import Header from '@components/Header';
import HeaderMobile from '@components/Header/Mobile/HeaderMobile';
import { useMediaQuery, useTheme } from '@mui/material';
import useCoupon from '@customhooks/useCoupon';

const fields = {
  first_name: '',
  last_name: '',
  email: '',
  mobile: '',
  salutation: 'Mr.',
};

const Booking = React.memo((props) => {
  const cookies = new Cookies();
  const { accessToken, refreshToken } = useAccessTokens();
  const { trackEvent } = useAnalytics();
  const { numberOfGuests, numberOfChildren, checkInDate, checkOutDate } =
    useBookingDetails();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const searchParams = new URLSearchParams(location?.search);
  const bookingId = searchParams.get('id');
  const propertyId = searchParams.get('propertyId');
  const paymentStatus = searchParams.get('payment_status');
  const paymentId = searchParams.get('payment_id');
  const paymentRequestId = searchParams.get('payment_gateway_code');

  const {} = props;

  const {
    isCouponRemoved,
    isHollowCouponRemoved,
    isCustomCouponRemoved,
    isCustomDoubleDipCouponRemoved,
  } = useCoupon();

  const [inputFields, setInputFields] = useState(fields);
  const [termsChecked, setTermsChecked] = useState(true);
  const [errors, setErrors] = useState({});
  const [basicDetails, setBasicDetails] = useState(false);
  const [isToggleOpen, setIsToggleOpen] = useState(true);
  const [isFetched, setIsFetched] = useState(false);
  const { fetchingUpdatingPricing, gettingUpdatedPricing, propertyDetail } =
    useSelector((state) => state.property);

  const { loader, open } = useSelector((state) => state.glob);

  const { gettingProfileDetails } = useSelector((state) => state?.profile);

  const handleSubmit = (e) => {
    trackEvent('Proceed to pay click', {
      salutation: inputFields.salutation,
      first_name: inputFields.first_name,
      last_name: inputFields.last_name,
      mobile: inputFields.mobile,
      email: inputFields.email,
      adults: parseInt(numberOfGuests),
      children: parseInt(numberOfChildren),
      checkin_on: dayjs(checkInDate, 'DD MMM,YYYY').format('YYYY-MM-DD'),
      checkout_on: dayjs(checkOutDate, 'DD MMM,YYYY').format('YYYY-MM-DD'),
      bookingId: parseInt(bookingId),
      property_id: parseInt(propertyId),
    });

    if (!accessToken && !refreshToken) {
      dispatch(setOpen(true));
    } else if (termsChecked) {
      let propertyPaymentId = null;
      const jsonData =
        gettingUpdatedPricing?.PaymentTerms ||
        gettingUpdatedPricing?.Property?.PaymentTerms;
      if (jsonData) {
        propertyPaymentId =
          pricingRefactored(jsonData)?.PropertyPaymentTerm?.id;
      }
      if (handleValidation()) {
        const data = {
          salutation: inputFields.salutation,
          first_name: inputFields.first_name,
          last_name: inputFields.last_name,
          mobile: inputFields.mobile,
          email: inputFields.email,
          adults: parseInt(numberOfGuests),
          children: parseInt(numberOfChildren),
          checkin_on: dayjs(checkInDate, 'DD MMM,YYYY').format('YYYY-MM-DD'),
          checkout_on: dayjs(checkOutDate, 'DD MMM,YYYY').format('YYYY-MM-DD'),
          bookingId: parseInt(bookingId),
          property_id: parseInt(propertyId),
          property_payment_term_id: isToggleOpen
            ? propertyPaymentId?.PropertyPaymentTerm?.id
            : null,
        };

        dispatch(createBookingRequest(data));
      } else {
        // Scroll to the #formSection element
        const formSection = document.getElementById('El-bookinfInfoForm');
        formSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      alert('Please accept the terms and conditions.');
    }
  };

  const handleValidation = () => {
    const errors = {};
    let formIsValid = true;

    const requiredFields = [
      'salutation',
      'first_name',
      'last_name',
      'mobile',
      'email',
    ];
    const fieldLabels = {
      salutation: 'Title',
      first_name: 'Full Name',
      last_name: 'full Name',
      mobile: 'Mobile Number',
      email: 'Email',
    };

    for (const field of requiredFields) {
      const value = inputFields[field];
      if (!value) {
        formIsValid = false;
        errors[field] = `Please enter guest’s ${fieldLabels[field]}`;
      } else if (field === 'mobile') {
        const mobileNumberWithoutSpaces = value.replace(/\D/g, '');

        if (mobileNumberWithoutSpaces.length !== 10) {
          formIsValid = false;
          errors[field] = `${fieldLabels[field]} must be a 10-digit number.`;
        } else if (/^[0-5]/.test(mobileNumberWithoutSpaces)) {
          // Check if the mobile number starts with 0 to 6.
          formIsValid = false;
          errors[field] = `${fieldLabels[field]} should not start with 0 to 5.`;
        }
      } else if (field === 'email') {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (!emailPattern.test(value)) {
          formIsValid = false;
          errors[field] = 'Invalid email address';
        }
      }
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'mobile' && (!/^\d*$/.test(value) || value.length === 11)) {
      return;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

    setInputFields({
      ...inputFields,
      [name]: value,
    });
  };

  useEffect(() => {
    if (propertyId) {
      dispatch(fetchingDatesAvailability(propertyId)).then(() => {
        setIsFetched(true);
      });
    }
  }, [propertyId]);

  useEffect(() => {
    if (bookingId && isFetched) {
      dispatch(getReviewBookingDetails(bookingId));
    }
  }, [
    bookingId,
    isFetched,
    isCouponRemoved,
    isHollowCouponRemoved,
    isCustomCouponRemoved,
    isCustomDoubleDipCouponRemoved,
  ]);

  useEffect(() => {
    setInputFields({
      salutation: gettingProfileDetails?.User?.salutation || 'Mr.',
      first_name: gettingProfileDetails?.User?.first_name || '',
      last_name: gettingProfileDetails?.User?.last_name || '',
      email: gettingProfileDetails?.User?.email || '',
      mobile: gettingProfileDetails?.User?.mobile || '',
    });
  }, [gettingProfileDetails]);

  useEffect(() => {
    dispatch(isPartialPaymentToggle(isToggleOpen));
  }, [isToggleOpen]);

  useEffect(() => {
    dispatch(fetchPropertyDetail(propertyId));
  }, [propertyId]);

  useEffect(() => {
    dispatch(setGuestDetails(inputFields));
  }, [inputFields]);

  return (
    <>
      <HelmetConfig
        title='ELIVAAS | Booking info'
        description='ELIVAAS | Booking info'
        keywords='Booking, page, keywords'
      />
      {isMobile ? (
        <HeaderMobile
          logoColor={'black'}
          sticky={true}
          transparentHeader={false}
        />
      ) : (
        <Header
          sticky={isMobile ? false : true}
          fixed={isMobile ? false : true}
          logoColor={'black'}
          className={'el-header el-detailHeader'}
        />
      )}
      <BookingInfo
        setInputFields={setInputFields}
        inputFields={inputFields}
        handleSubmit={handleSubmit}
        setTermsChecked={setTermsChecked}
        termsChecked={termsChecked}
        errors={errors}
        handleInputChange={handleInputChange}
        setBasicDetails={setBasicDetails}
        fetchingUpdatingPricing={fetchingUpdatingPricing}
        gettingUpdatedPricing={gettingUpdatedPricing}
        loader={loader}
        paymentStatus={paymentStatus}
        bookingId={bookingId}
        propertyId={propertyId}
        paymentId={paymentId}
        paymentRequestId={paymentRequestId}
        setIsToggleOpen={setIsToggleOpen}
        isToggleOpen={isToggleOpen}
      />
    </>
  );
});

export default Booking;
