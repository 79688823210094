import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Divider,
} from '@mui/material';
import Skeleton from '@components/Common/Skeleton';
import React from 'react';
import './faq.scss';

const WalletFaqs = (props) => {
  const { className, data, loading } = props;
  const [expanded, setExpanded] = React.useState('faq0');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className={className}>
      <Typography component='h4' variant='h4'>
        FAQs
      </Typography>

      {loading ? (
        <div className='El-myWalletFaqSkeletonMain'>
          {Array.from({ length: 3 }, (_, index) => (
            <>
              <div className='El-myWalletFaqSkeleton'>
                <Skeleton
                  key={index}
                  component='p'
                  variant='text'
                  width={'80%'}
                />
                <Skeleton
                  key={index}
                  component='p'
                  variant='text'
                  width={'60%'}
                />
                <Skeleton
                  key={index}
                  component='p'
                  variant='text'
                  width={'40%'}
                />
              </div>
              <Divider />
            </>
          ))}
        </div>
      ) : (
        data?.map((faq, index) => (
          <Accordion
            key={`accordion${index}`}
            expanded={expanded === `faq${index}`}
            onChange={handleChange(`faq${index}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
            >
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className='el-detailFaqList'>
                <Typography>{faq.answer}</Typography>
              </div>
            </AccordionDetails>
          </Accordion>
        ))
      )}
    </div>
  );
};

export default WalletFaqs;
