import { useSelector } from 'react-redux';

const useCoupon = () => {
  const {
    fetchingCouponList,
    gettingCouponList,
    isCouponApplied,
    isHollowCouponApplied,
    isCustomCouponApplied,
    isCustomDoubleDipCouponApplied,
    gettingCouponResponse,
    couponId,
    couponCode,
    isCouponRemoved,
    isHollowCouponRemoved,
    isCustomCouponRemoved,
    isCustomDoubleDipCouponRemoved,
    isDoubleDipCouponApplicable,
  } = useSelector((state) => state.coupon);

  return {
    fetchingCouponList,
    gettingCouponList,
    isCouponApplied,
    isHollowCouponApplied,
    isCustomCouponApplied,
    isCustomDoubleDipCouponApplied,
    gettingCouponResponse,
    couponId,
    couponCode,
    isCouponRemoved,
    isHollowCouponRemoved,
    isCustomCouponRemoved,
    isCustomDoubleDipCouponRemoved,
    isDoubleDipCouponApplicable,
  };
};

export default useCoupon;
