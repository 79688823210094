import { Payment } from '@assets';
import Skeleton from '@components/Common/Skeleton';
import CouponSkeleton from '@components/Skeleton/CouponSleleton';
import GuestSleleton from '@components/Skeleton/GuestSleleton';

import Svg from '@components/Common/Svg';
import {
  Avatar,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from '@mui/material';
import {
  fetchPropertyList,
  homePageDetailsSections,
} from '@reducers/propertyReducer';
import { useInView } from 'react-intersection-observer';
import initializeAOS from '@utils/aos';
import { CONSTANT_PAGES_URL } from '@utils/constants';
import { progress } from '@utils/customText';
import LazyImage from '@utils/lazyLoadImage';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ListingSkeleton } from '@utils/skeleton';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
// Import Swiper styles

import 'swiper/css';
import './home.scss';
import { useNavigate } from 'react-router-dom';

import PropertyFilterBar from '@components/PropertyFilterBar';
import CallBackRequest from '@components/CallBack';
const Slider = lazy(() => import('./Slider'));
const TrustElements = lazy(() => import('./TrustElements'));
const Testimonial = lazy(() => import('./Testimonial'));
const GuestExperince = lazy(() => import('./GuestExperince'));
// const Instagram = lazy(() => import('./Instagram'));
const PropetySlider = lazy(() => import('./Property'));
const PaymentLogos = lazy(() => import('./PaymentLogos'));
const CouponList = lazy(() => import('@components/Coupons'));

export const Home = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const [isPaused, setIsPaused] = useState(false);

  const togglePause = () => {
    setIsPaused((prevState) => !prevState);
  };

  const [guestExperinceRef, isGuestExperinceView] = useInView({
    triggerOnce: true,
  });
  const [guestTrustElement, isTrustElementView] = useInView({
    triggerOnce: true,
  });
  const [paymentLogosRef, isPaymentLogosView] = useInView({
    triggerOnce: true,
  });

  const [herobannerRef, isHerobannerView] = useInView({
    triggerOnce: true,
  });
  const [testimonialsRef, isTestimonialsView] = useInView({
    triggerOnce: true,
  });
  const [testimonials1Ref, isTestimonials1View] = useInView({
    triggerOnce: true,
  });
  const [testimonial2sRef, isTestimonials2View] = useInView({
    triggerOnce: true,
  });
  const [propertySliderRef, isPropertySliderInView] = useInView({
    triggerOnce: true,
  });

  const [couponListRef, isCouponListInView] = useInView({
    triggerOnce: true,
  });
  const [searchListRef, isSearchInView] = useInView({
    triggerOnce: true,
  });

  //const [isBlockList, setblockList] = React.useState([]);

  const [combineTestimonials, setCombineTestimonials] = useState([]);
  const [isDrawerSearchOpen, setIsDrawerSearchOpen] = useState(false);
  const [isDrawerCallBackOpen, setIsDrawerCallBackOpen] = useState(false);

  const { dataJson } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { pagesDetailsSectionsData, propertyListData } = useSelector(
    (state) => state.property
  );
  const propertyList = propertyListData?.data;
  const { guestExperince, makeHome } = dataJson;
  const isBlockList = pagesDetailsSectionsData;
  const handleViewALlProperties = () => {
    navigate('/villas');
  };
  useEffect(() => {
    initializeAOS();
  }, []);

  useEffect(() => {
    const { nameSpace, slug } = CONSTANT_PAGES_URL.home;
    if (isHerobannerView) {
      dispatch(homePageDetailsSections(nameSpace, slug));
    }
  }, [isHerobannerView]);

  useEffect(() => {
    if (isPropertySliderInView) {
      dispatch(fetchPropertyList());
    }
  }, [isPropertySliderInView]);

  const combinedSections = [];

  useEffect(() => {
    const pageBlocks =
      isBlockList &&
      isBlockList?.PageBlocks &&
      isBlockList?.PageBlocks[0].Sections;
    if (pageBlocks && pageBlocks.length > 2) {
      for (let i = 0; i < pageBlocks.length; i += 3) {
        combinedSections.push(pageBlocks.slice(i, i + 3));
      }
    }
    setCombineTestimonials(combinedSections);
  }, [isTestimonialsView]);

  const PropertySkeleton = Array.from(
    { length: isMobile ? 1 : 3 },
    (_, index) => (
      <ListingSkeleton mobileView={isMobile} key={`propertyList${index}`} />
    )
  );
  return (
    <>
      {/* slider section */}
      <Container
        className='EL-homebannerMain'
        ref={herobannerRef}
        disableGutters
        maxWidth='xl'
      >
        {isHerobannerView && isBlockList && (
          <Suspense
            fallback={
              <div className='EL-bannerSkeleton'>
                <Skeleton variant='text' width={'100%'} height={'446px'} />
              </div>
            }
          >
            <Slider
              slidesPerView={1}
              data={
                isBlockList?.ImageBlocks && isBlockList?.ImageBlocks[1]?.Images
              }
              autoplay={isMobile ? true : false}
              navigation={isMobile ? false : true}
              loop={true}
              pagination={false}
              effect={isMobile ? 'fade' : ''}
              className='elMainBanner'
            />
          </Suspense>
        )}

        {/* Search Filter */}

        <Container
          ref={searchListRef}
          id='propertyFilterbar'
          className='EL-propertyFilterbar'
        >
          {isSearchInView && (
            <>
              <PropertyFilterBar
                title='Where do you want to go?'
                pageType={'home'}
                buttonLabel='Search'
                isDrawerOpen={isDrawerSearchOpen}
                setIsDrawerOpen={setIsDrawerSearchOpen}
              />
              {!isMobile && (
                <CallBackRequest
                  className={'EL-homeCallback'}
                  isDrawerOpen={isDrawerCallBackOpen}
                  setIsDrawerOpen={setIsDrawerCallBackOpen}
                />
              )}
            </>
          )}
        </Container>

        {/* Search Filter end */}
      </Container>
      {/* slider section end */}
      {/* {isMobile && (
        <Container
          className='EL-propertyFilterbarMobile'
          ref={searchListRef}
          id='propertyFilterbar'
        >
          {isSearchInView && (
            <>
              <PropertyFilterBar
                title='Where do you want to go?'
                pageType={'home'}
                buttonLabel='Search'
                isDrawerOpen={isDrawerSearchOpen}
                setIsDrawerOpen={setIsDrawerSearchOpen}
              />
            </>
          )}
        </Container>
      )} */}

      {/* Property list */}
      <Container
        id='propertyList'
        maxWidth='100%'
        className='el-section EL-homeproPertyList'
      >
        <Container
          ref={propertySliderRef}
          className='propertySlider'
          maxWidth='lg'
        >
          <Suspense
            fallback={
              <div className='slekton-flex full-width HomePropertySkeleton'>
                {PropertySkeleton}
              </div>
            }
          >
            {isPropertySliderInView && (
              <>
                <div className='section-header'>
                  <Typography variant='h1' component='h1'>
                    Explore our Villas
                  </Typography>
                </div>
                <PropetySlider
                  data={propertyList}
                  itemCount={isMobile ? 2 : 6}
                />
                <Button
                  disableElevation
                  color='primary'
                  variant='contained'
                  size='large'
                  className='El-ExpandButtonWeb'
                  onClick={handleViewALlProperties}
                >
                  View all
                </Button>
              </>
            )}
          </Suspense>
        </Container>
      </Container>
      {/* Property list end */}

      {/* Offers */}
      <Container maxWidth='100%' className='el-section offerSection section-bg'>
        <div className='section-header'>
          <h3>Offers</h3>
        </div>
        <div className='MuiContainer-maxWidthLg'>
          <Suspense fallback={<CouponSkeleton listLength={4} />}>
            <CouponList
              applyAction={false}
              moreDetailAction='modal'
              isSlider={true}
              allOffersList={true}
              title={true}
              titleText={'Offers'}
              skeletonLength={isMobile ? 1 : 4}
            />
          </Suspense>
        </div>
      </Container>
      {/* Offers end */}

      {/* Trust Elements start */}
      <div className='trustElementSkeleton' ref={guestTrustElement}>
        {isTrustElementView ? (
          <Suspense
            fallback={<Skeleton width='100%' height={isMobile ? 503 : 335} />}
          >
            <TrustElements />
          </Suspense>
        ) : (
          <Skeleton width='100%' height={isMobile ? 503 : 335} />
        )}
      </div>

      {/* Trust Elements ends */}

      {/* guest experince section */}
      <Container
        ref={guestExperinceRef}
        maxWidth='lg'
        className='el-section el-guest'
      >
        <Suspense
          fallback={
            <div>
              {' '}
              <GuestSleleton listLength={6} />
            </div>
          }
        >
          {isGuestExperinceView && guestExperince ? (
            <GuestExperince data={guestExperince} />
          ) : (
            <GuestSleleton listLength={6} />
          )}
        </Suspense>
      </Container>
      {/* guest experince section end */}

      {/* Testimonial */}
      <div>
        {isBlockList &&
          isBlockList?.PageBlocks?.length >= 0 &&
          isBlockList?.PageBlocks[2] && (
            <Container maxWidth='100%' className='el-section section-bg'>
              <Container className='el-testimonial' maxWidth='lg'>
                <div ref={testimonialsRef} className='section-header'>
                  <h3>Stories from our world</h3>
                </div>
                <div className='section-header'>
                  <h4>{isBlockList?.PageBlocks[2]?.name}</h4>
                </div>

                {isTestimonialsView && (
                  <Suspense
                    fallback={
                      <div className='flex sp-bw ai-center'>
                        <Grid
                          container
                          direction='row'
                          spacing={{ xs: 0, sm: 5 }}
                          justifyContent='space-between'
                        >
                          {Array.from(
                            { length: isMobile ? 1 : 4 },
                            (_, index) => (
                              <Grid key={index} item xs={12} sm={3}>
                                <Skeleton
                                  variant='rectangular'
                                  // width={'100%'}
                                  height={176}
                                />
                                <Box sx={{ pt: 0.5 }}>
                                  <Skeleton width='40%' />
                                  <Skeleton />
                                  <Skeleton />
                                  <Skeleton />
                                  <Skeleton width='20%' />
                                </Box>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </div>
                    }
                  >
                    <Testimonial
                      testimonialList={isBlockList?.PageBlocks[2]?.Sections}
                      slidesPerView={4}
                      slidesPerViewMobile={1}
                      className='asd'
                      vertical={true}
                      modules='navigation'
                      spaceBetween={45}
                    />
                  </Suspense>
                )}
              </Container>
            </Container>
          )}
      </div>

      {/* Testimonial */}
      <div ref={testimonials1Ref}>
        {isBlockList &&
          isBlockList?.PageBlocks?.length >= 1 &&
          isBlockList?.PageBlocks[1] && (
            <Container maxWidth='100%' className='el-section '>
              <Container className='el-testimonial' maxWidth='lg'>
                <div className='section-header'>
                  <h4>{isBlockList?.PageBlocks[1]?.name}</h4>
                </div>
                <Suspense fallback={<div>{progress.loading}</div>}>
                  {isTestimonials1View && (
                    <Testimonial
                      testimonialList={isBlockList?.PageBlocks[1]?.Sections}
                      slidesPerView={4}
                      slidesPerViewMobile={1}
                      className='asd'
                      vertical={true}
                      modules='navigation'
                      spaceBetween={45}
                    />
                  )}
                </Suspense>
              </Container>
            </Container>
          )}
      </div>
      {/* Testimonial  end*/}

      {/* Makes a home section */}
      <Container ref={ref} maxWidth='lg' className='el-section makeHome'>
        <Box sx={{ flexGrow: 1 }}>
          {inView && (
            <Grid
              container
              direction='row'
              spacing={{ xs: 2, sm: 3 }}
              justifyContent='space-between'
            >
              <Grid className='el-makeHomeImg' item xs={12} sm={6}>
                <LazyImage
                  src={makeHome?.img}
                  alt={makeHome?.name}
                  title={makeHome?.name}
                  width={isMobile ? '450' : '800'}
                  effect='blur'
                  className='full-width'
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography sx={{ mt: 4, mb: 2 }} variant='h3' component='h3'>
                  {makeHome?.title}
                </Typography>
                <List>
                  {makeHome &&
                    makeHome?.data.map((item, index) => (
                      <ListItem key={'makeHome' + index}>
                        <ListItemAvatar>
                          <Avatar
                            sx={{ width: 56, height: 56 }}
                            variant='rounded'
                          >
                            <LazyImage
                              src={item?.image}
                              alt={item?.name}
                              title={item?.name}
                              effect='blur'
                              width={isMobile ? '35' : '45'}
                            />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item?.title} />
                      </ListItem>
                    ))}
                </List>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
      {/*  Makes a home section  end */}

      {/* Instagram */}
      {/* <Container maxWidth='100%' className='el-section section-bg'>
        <Container disableGutters maxWidth='lg' md={{ p: 0 }}>
          <Suspense fallback={<div>{progress.loading}</div>}>
            <Instagram data={instagram} />
          </Suspense>
        </Container>
      </Container> */}
      {/* Instagram end*/}

      <div ref={paymentLogosRef}>
        {isBlockList &&
          isBlockList?.ImageBlocks &&
          isBlockList?.ImageBlocks[0]?.Images?.length > 0 && (
            <>
              <Typography
                className='El-paymentTitle'
                variant='h5'
                component='h5'
              >
                <Svg src={Payment} />
                {/* <LazyImage
                  src={Payment}
                  alt={'Secure Payment'}
                  effect='blur'
                  width={isMobile ? '100' : '150'}
                  //   className='full-width'
                /> */}
                {isBlockList?.ImageBlocks[0]?.title}
              </Typography>
              <Container
                maxWidth='100%'
                className='el-section section-bg section-gray'
              >
                <Container disableGutters maxWidth='lg' md={{ p: 0 }}>
                  <Suspense fallback={<div>{progress.loading}</div>}>
                    {isPaymentLogosView && (
                      <PaymentLogos
                        className='paymentLogos'
                        data={isBlockList?.ImageBlocks[0]?.Images}
                      />
                    )}
                  </Suspense>
                </Container>
              </Container>
            </>
          )}
      </div>
    </>
  );
};
