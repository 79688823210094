import { LogoBlack } from '@assets';
import Header from '@components/Header';
import HeaderMobile from '@components/Header/Mobile/HeaderMobile';
import { Grid, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import HelmetConfig from '@seo/HelmetConfig';
import React from 'react';
import './404.scss';

const NotFoundPage = (props) => {
  const {} = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <HelmetConfig
        title='ELIVAAS | NotFoundPage'
        description='ELIVAAS | 404 - Page not found'
        keywords='404, Page not found'
      />

      {isMobile ? (
        <HeaderMobile
          logoColor={'black'}
          sticky={true}
          transparentHeader={false}
        />
      ) : (
        <Header
          sticky={isMobile ? false : true}
          fixed={isMobile ? false : true}
          logoColor={'black'}
          className={'el-header el-detailHeader'}
        />
      )}

      <Grid
        container
        justifyContent='center'
        alignItems='center'
        style={{ minHeight: '91vh', backgroundColor: '#f5f5f5' }} // Apply a background color
        className='EL-404'
      >
        <Grid item xs={12} sm={8} md={6} style={{ textAlign: 'center' }}>
          <div className='flex ai-center EL-404Icon'>
            <Link href='/'>
              <img src={LogoBlack} alt='ELIVAAS logo' />
            </Link>
          </div>
          <Typography variant='h4' align='center'>
            Oops! Page Not Found
          </Typography>
          <Typography variant='body1' align='center'>
            Sorry, the page you're looking for doesn't exist.
          </Typography>
          <Typography variant='body2' align='center' style={{ marginTop: 16 }}>
            Return to{' '}
            <Link href='/' style={{ textDecoration: 'underline' }}>
              Home
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default NotFoundPage;
