// const dotenv = require('dotenv');

// dotenv.config();
module.exports = {
  footerLinks: {
    villaLinks: [
      { id: 1, name: 'Villas in Udaipur', link: '/villas/villas-in-Udaipur' },
      { id: 2, name: 'Villas in Goa', link: '/villas/villas-in-Goa' },
      { id: 3, name: 'Villas in NCR', link: '/villas/villas-in-Delhi-NCR' },
      {
        id: 4,
        name: 'Villas in Faridabad',
        link: '/villas/villas-in-Faridabad',
      },
    ],
    company: [
      { id: 1, name: 'About us', link: '/explore/about' },
      { id: 2, name: 'Team', link: '/explore/team' },
      { id: 3, name: 'Careers', link: '/explore/careers' },
      { id: 4, name: 'Blogs', link: '/explore/blogs' },
      { id: 5, name: 'Contact us', link: '/explore/contact/' },
      { id: 6, name: 'Press release', link: '/explore/press-release/' },
    ],
    legal: [
      { id: 1, name: 'Partner with us', link: '/explore/partner' },
      {
        id: 1,
        name: 'Corporate offsites',
        link: '/explore/corporate-offsites/',
      },
      { id: 2, name: 'Privacy policy', link: '/explore/privacy-policy' },
      {
        id: 3,
        name: 'Terms and conditions',
        link: '/explore/terms-and-conditions',
      },
    ],
  },
  socialLinks: [
    {
      id: 1,
      name: 'instagram',
      link: 'https://www.instagram.com/stay.elivaas/',
    },
    {
      id: 2,
      name: 'facebook',
      link: 'https://www.facebook.com/stay.elivaas',
    },
    { id: 3, name: 'youtube', link: 'https://www.youtube.com/@stay.elivaas' },

    {
      id: 4,
      name: 'linkedIn',
      link: 'https://www.linkedin.com/company/elivaas/mycompany/',
    },
    { id: 5, name: 'whatsapp', link: 'https://wa.me/+917827449532' },
  ],
  headerLinks: [
    { id: 1, name: 'About us', link: '/explore/about' },
    { id: 2, name: 'Our team', link: '/explore/team' },
    { id: 3, name: 'Partner with us', link: '/explore/partner' },
  ],
  guestExperince: {
    title: 'The Guest Experience you deserve',
    subtitle:
      'Our aim is to make your trip memorable. To ensure you spend your time right, we have a lot of services to avoid any hassle for you.',
    data: [
      {
        id: 1,
        title: '100% on-ground assistance & digital concierge',
        image: 'https://d31za8na64dkj7.cloudfront.net/service2_348e83ca15.webp',
      },
      {
        id: 2,
        title: '5 star amenities rich property',
        image: 'https://d31za8na64dkj7.cloudfront.net/safty_7fd5d685ee.webp',
      },
      {
        id: 3,
        title: 'Safety and security',
        image: 'https://d31za8na64dkj7.cloudfront.net/service6_d68bf3d581.webp',
      },
      {
        id: 4,
        title: 'Offers on popular local experiences',
        image: 'https://d31za8na64dkj7.cloudfront.net/service3_daf01defb0.webp',
      },
      {
        id: 5,
        title: 'Welcome drinks and gift basket',
        image: 'https://d31za8na64dkj7.cloudfront.net/service1_7eb0634d8c.webp',
      },
      {
        id: 6,
        title: 'On-call chef',
        image: 'https://d31za8na64dkj7.cloudfront.net/service4_c4994da652.webp',
      },
    ],
  },

  makeHome: {
    title: 'What makes a home ELIVAAS?',
    img: 'https://d31za8na64dkj7.cloudfront.net/alt_services_e60e1a16bb.jpg',
    data: [
      {
        id: 1,
        title: 'Ideal location',
        image: 'https://d31za8na64dkj7.cloudfront.net/location_86e27a7413.webp',
      },
      {
        id: 2,
        title: 'Elegant Property',
        image: 'https://d31za8na64dkj7.cloudfront.net/elegant_36dca8453a.webp',
      },
      {
        id: 3,
        title: 'Commitment to the best guest experience',
        image:
          'https://d31za8na64dkj7.cloudfront.net/experince_d204e7a940.webp',
      },
      {
        id: 3,
        title: 'Best in class amenities ',
        image:
          'https://d31za8na64dkj7.cloudfront.net/amenities_e0e4be7a56.webp',
      },
    ],
  },
};
