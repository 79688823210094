import MyProfile from '@components/Account/Profile';
import HelmetConfig from '@seo/HelmetConfig';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getProfileDetail } from '@reducers/profileReducer';
import { useAccessTokens } from '@customhooks/useTokens';

const MyProfileContainer = (props) => {
  const {} = props;
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAccessTokens();

  useEffect(() => {
    const fetchData = async () => {
      if (accessToken && refreshToken) {
        await dispatch(getProfileDetail());
      }
    };

    fetchData();
  }, [accessToken, refreshToken, dispatch]); // Make sure to include the dependencies

  return (
    <>
      <HelmetConfig
        title='ELIVAAS | My Account'
        description='My Account'
        keywords='My Account'
      />
      <MyProfile />
    </>
  );
};

export default MyProfileContainer;
