import * as React from 'react';
import {
  Typography,
  List,
  ListItem,
  Divider,
  ListItemText,
  Button,
  Chip,
} from '@mui/material';
import dayjs from 'dayjs';
import Svg from '@components/Common/Svg';
import { Elicash } from '@assets';

// checking status for chip color
const chipColor = (status) => {
  if (status == 'expiring') {
    return 'info';
  } else if (status == 'expired') {
    return 'error';
  } else if (status == 'consumed') {
    return 'error';
  } else if (status == 'unlocking') {
    return 'secondary';
  }
  return 'info';
};

const walletTitle = (status) => {
  if (status == 'expiring') {
    return 'Cashback on booking at ELIVAAS';
  } else if (status == 'expired') {
    return 'Cashback on booking at ELIVAAS';
  } else if (status == 'consumed') {
    return 'Redeemed on booking at ELIVAAS';
  } else if (status == 'unlocking') {
    return 'Cashback on booking at ELIVAAS';
  }
  return 'Cashback on booking at ELIVAAS';
};

const cashBackAmount = (data, tabStatus) => {
  if (tabStatus == 'expiringTab') {
    return data?.remaining_amount;
  } else if (data.status == 'expired') {
    return data?.remaining_amount;
  } else {
    return data?.amount;
  }
};

export const MyWalletItems = (props) => {
  const { data, tabStatus } = props;
  return (
    <List className='EL-MyWalletItems' disablePadding>
      <ListItem
        secondaryAction={
          <span className={`El-cashAmt ${chipColor(data?.status)}`}>
            <Svg src={Elicash} alt='Gold membership' />
            {data?.status == 'expired' || data?.status == 'consumed'
              ? '-'
              : '+'}
            {cashBackAmount(data, tabStatus)}
          </span>
        }
        alignItems='flex-start'
      >
        <ListItemText
          primary={
            data?.Config?.id == 2
              ? `Referral bonus for ${data?.Booking?.ota_booking_code}`
              : walletTitle(data?.status)
          }
          secondary={
            <React.Fragment>
              <Button
                variant='text'
                disableFocusRipple
                color='info'
                href={`/booking-detail/${data?.booking_id}`}
              >
                {data?.Booking?.ota_booking_code &&
                  data?.Config?.id !== 2 &&
                  `Booking ID: ${data?.Booking?.ota_booking_code}`}
              </Button>

              <Typography variant='body2' component='p'>
                {data?.status !== 'expired' && data?.status !== 'consumed' && (
                  <Typography variant='body2' component='p'>
                    {dayjs(data?.created_at).format('DD MMM YYYY')}
                  </Typography>
                )}
                {data?.status == 'expired' || data?.status == 'unlocking' ? (
                  <Chip
                    label={data?.status}
                    size='small'
                    color={chipColor(data?.status)}
                  />
                ) : (
                  data?.status !== 'expired' &&
                  data?.status !== 'consumed' &&
                  'Validity: '
                )}
                {data?.status !== 'unlocking' &&
                  data?.status !== 'consumed' &&
                  dayjs(data?.expiry_date).format('DD MMM YYYY')}
                {data?.status == 'consumed' &&
                  data?.created_at &&
                  dayjs(data?.created_at).format('DD MMM YYYY')}
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider component='li' />
    </List>
  );
};
