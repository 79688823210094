import Home from '@components/Home';
import HelmetConfig from '@seo/HelmetConfig';
import React, { useEffect, Suspense } from 'react';
import dataJson from '../../data.js';

const Header = React.lazy(() => import('@components/Header'));
const HeaderMobile = React.lazy(() =>
  import('@components/Header/Mobile/HeaderMobile')
);
import { useMediaQuery, useTheme } from '@mui/material';

const HomePage = () => {
  useEffect(() => {
    sessionStorage.removeItem('copyData');
  }, []);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <HelmetConfig
        title='Luxury Villa Vacation Rental: Premium Holiday Homes & Weekend Getaways'
        description='Discover exclusive Luxury Villas for short rentals. Find the perfect vacation house or weekend home. Book your dream homestay with us today!'
        keywords='Luxury Villas for Rent, Luxury Villas, Luxury villas for short rentals, holiday homes, homestay, villas, villas on rent, vacation house, weekend home'
      />
      {isMobile ? (
        <Suspense fallback={<div></div>}>
          <HeaderMobile
            logoColor={'white'}
            sticky={true}
            transparentHeader={true}
          />
        </Suspense>
      ) : (
        <Suspense fallback={<div></div>}>
          <Header
            sticky={isMobile ? false : true}
            fixed={isMobile ? false : true}
            logoColor={'white'}
            className={'el-header'}
          />
        </Suspense>
      )}
      <Home dataJson={dataJson} />
    </>
  );
};

export default HomePage;
