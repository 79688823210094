import { COUNTER, upDateCounter } from '@actions/counterAction';

//---------------------INITIAL STATE-------------------

const initialState = {
  count: [],
};

// demo api
export const fetchUsers = () => {
  return (dispatch) => {
    // Perform an asynchronous operation (e.g., API call)
    fetch(`${process.env.APP_GATEWAY_URL}/gateways/visitor/api/properties`)
      .then((response) => response.json())
      .then((data) => {
        dispatch(upDateCounter(data));
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
};

//--------------------REDUCER-------------------------
const counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case COUNTER:
      return {
        ...state,
        count: action.payload,
      };
    default:
      return state;
  }
};

export default counterReducer;
