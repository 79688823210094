// HelmetConfig.js
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import useAnalytics from '@components/Common/Analytics';
import { getParameterByName } from '@utils/common';
import { useSelector } from 'react-redux';

const HelmetConfig = ({ title, description, keywords }) => {
  const { trackEvent } = useAnalytics();
  const urlLocation = useLocation();
  const { utmCampaign, utmSource } = useSelector((state) => state?.glob);

  useEffect(() => {
    // Extract utm_campaign from the URL
    const utmCampaign = getParameterByName(
      'utm_campaign',
      window.location.href
    );

    // Set utm_campaign in local storage
    if (utmCampaign !== null) {
      localStorage.setItem('utm_campaign', utmCampaign);
    }

    // Extract utm_source from the URL
    const utmSource = getParameterByName('utm_source', window.location.href);
    if (utmSource !== null) {
      // Set utm_source in local storage
      localStorage.setItem('utm_source', utmSource);
    }
  }, []); // Run the effect only once when the component mounts

  useEffect(() => {
    let timeoutId;

    if (title) {
      timeoutId = setTimeout(() => {
        trackEvent('Page View', {
          url: `https://www.elivaas.com${urlLocation?.pathname}`,
          page_title: title,
          utmCampaign: utmCampaign,
          utmSource: utmSource,
        });
      }, 3000);
    }

    // Clean up the timeout
    return () => {
      clearTimeout(timeoutId);
    };
  }, [title, utmCampaign]); // Run the effect only when the title prop changes

  const canonicalUrl =
    `https://www.elivaas.com${urlLocation?.pathname}`.toLowerCase();

  return (
    <Helmet defaultTitle='ELIVAAS'>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <link rel='canonical' href={canonicalUrl} />
    </Helmet>
  );
};

export default HelmetConfig;
