import {
  useTheme,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Card,
  CardContent,
  Dialog,
  Divider,
  Drawer,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import { useAccessTokens } from '@customhooks/useTokens';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Svg from '@components/Common/Svg';
import MyWalletTabs from './Tabs';
import WalletFaqs from '../FAQs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Skeleton from '@components/Common/Skeleton';
import React, { useEffect } from 'react';
import { Elicash, MyWalletBg } from '@assets';
import { getWalletListing, getFaqListing } from '@reducers/walletReducer';
import './myWallet.scss';
import { useState } from 'react';

const MyWallet = (props) => {
  const dispatch = useDispatch();
  const { accessToken, refreshToken } = useAccessTokens();
  const [openFaq, setFaqOpen] = React.useState(false);
  const [faqs, setFaqs] = useState([]);
  const { data } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    getWalletDetail,
    loading,
    gettingWalletListLoading,
    getWalletList,
    getFaqs,
    getFaqsloading,
  } = useSelector((state) => state.wallet);

  useEffect(() => {
    dispatch(getWalletListing());
  }, [accessToken, refreshToken]);

  useEffect(() => {
    if (openFaq) {
      dispatch(getFaqListing());
    }
  }, [openFaq]);

  useEffect(() => {
    setFaqs(
      getFaqs?.data?.map((item) => ({
        question: item?.attributes?.question,
        answer: item?.attributes?.answer,
      }))
    );
  }, [getFaqsloading]);

  const { loader } = useSelector((state) => state?.glob);

  const handleClickOpen = () => {
    setFaqOpen(true);
  };

  const handleClose = () => {
    setFaqOpen(false);
  };

  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <AppBar
          className='EL-myWalletHeader'
          color='transparent'
          position='static'
        >
          <Toolbar>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              sx={{ mr: 2 }}
              onClick={goBack}
            >
              {isMobile ? (
                <ArrowBackIcon fontSize='inherit' />
              ) : (
                <ArrowBackIosNewRoundedIcon />
              )}
            </IconButton>
            <Typography
              onClick={goBack}
              variant='h6'
              component='div'
              sx={{ flexGrow: 1 }}
            >
              My Wallet
            </Typography>
            <Button onClick={handleClickOpen} color='inherit'>
              FAQ’s
            </Button>
          </Toolbar>
        </AppBar>
        <div className='EL-MyWalletCardMain'>
          <Card
            className='EL-MyWalletCard'
            style={{ backgroundImage: 'url(' + MyWalletBg + ')' }}
          >
            <CardContent>
              <div className='El-wallerTopCard'>
                <Typography level='body-lg' className='El-myWalletInfo'>
                  <Svg src={Elicash} alt='Gold membership' />{' '}
                  <b>{getWalletDetail?.total_cashback}</b> <span> Elicash</span>
                </Typography>
                {getWalletDetail?.latest_expiring_cashback == null ? (
                  ''
                ) : (
                  <Typography className='El-myWalletError' level='body-lg'>
                    {getWalletDetail?.latest_expiring_cashback?.amount} Elicash
                    will be expiring by{' '}
                    {dayjs(
                      getWalletDetail?.latest_expiring_cashback?.expiry_date
                    ).format('DD MMM, YYYY')}
                    .
                  </Typography>
                )}
              </div>
            </CardContent>
          </Card>
        </div>
        <div className='EL-MyWalletList'>
          {gettingWalletListLoading ? (
            <div className='El-myWalletSkeletonMain'>
              {Array.from({ length: 6 }, (_, index) => (
                <>
                  <div className='El-myWalletSkeleton'>
                    <Skeleton
                      key={index}
                      component='p'
                      variant='text'
                      width={'80%'}
                    />
                    <Skeleton
                      key={index}
                      component='p'
                      variant='text'
                      width={'60%'}
                    />
                    <Skeleton
                      key={index}
                      component='p'
                      variant='text'
                      width={'40%'}
                    />
                  </div>
                  <Divider />
                </>
              ))}
            </div>
          ) : (
            <MyWalletTabs walletList={getWalletList} />
          )}
        </div>
      </Box>
      {/* faq drawer */}
      {!isMobile ? (
        <Dialog
          maxWidth='md'
          className='El-MywalletFaq El-MywalletFaqModal'
          open={openFaq}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <IconButton onClick={handleClose} className='El-MywalletFaqClose'>
            <CloseIcon />
          </IconButton>
          <WalletFaqs
            loading={getFaqsloading}
            data={faqs}
            className='EL-faqWallet'
          />
        </Dialog>
      ) : (
        <Drawer
          anchor={'bottom'}
          open={openFaq}
          onClose={handleClose}
          className='El-MywalletFaq El-MywalletFaqDrawer'
        >
          <IconButton onClick={handleClose} className='El-MywalletFaqClose'>
            <CloseIcon />
          </IconButton>
          <WalletFaqs
            loading={getFaqsloading}
            data={faqs}
            className='EL-faqWallet'
          />
        </Drawer>
      )}
    </>
  );
};

export default MyWallet;
