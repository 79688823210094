import { Wallet } from '@components/Wallet';
import Header from '@components/Header';
import HeaderMobile from '@components/Header/Mobile/HeaderMobile';
import { useAccessTokens } from '@customhooks/useTokens';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  bookingDetails,
  bookingDetailsContent,
} from '@reducers/bookingReducer';
import HelmetConfig from '@seo/HelmetConfig';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

const WalletContainer = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { accessToken, refreshToken } = useAccessTokens();

  const {} = props;

  useEffect(() => {
    const bookingId = location.pathname.split('/')[2];
    dispatch(bookingDetails(bookingId));
    dispatch(bookingDetailsContent(bookingId));
  }, [accessToken, refreshToken]);

  return (
    <>
      <HelmetConfig
        title='ELIVAAS | Wallet'
        description='ELIVAAS | Elicash'
        keywords='Elicash, wallet'
      />

      <Wallet />
    </>
  );
};

export default WalletContainer;
