import {
  AVAILABILITY_CALENDER_DATES,
  COPYING_AVAILABILITY_CALENDER_DATES,
  FETCHING_BOOKING_STATUS,
  FETCHING_UPDATED_PRICING,
  GETTING_BOOKING,
  GETTING_UPDATED_PRICING,
  GET_CITY_LIST,
  GET_MINIMUM_STAYS,
  PAGES_DETAILS_SECTION_DATA,
  PAGES_FOOTER_SECTION_DATA,
  PROPERTYLIST,
  PROPERTY_DETAIL,
  availableDates,
  bookingStatus,
  copyAvailableDates,
  fetchingUpdatedPricing,
  getCityList,
  getMinimumStays,
  gettingBookingRes,
  gettingUpdatedPricingRes,
  pagesDetailsData,
  pagesFooterData,
  propertyDetails,
  // Make sure you have the correct action creators imported here
  updatePropertyList,
} from '@actions/propertiesAction';
import { handleErrorResponse } from '@utils/common';
import axios from 'axios';
import { instance } from '../instance/axios';

import { checkAvailabilityLoader, loadingImage } from './globalReducers';

//---------------------INITIAL STATE-------------------

const initialState = {
  propertyListData: [],
  propertyDetail: {},
  availabilityDates: {},
  fetchingUpdatingPricing: false,
  gettingUpdatedPricing: {},
  isLoading: false,
  isBookingSuccess: false,
  gettingBookingResponse: '',
  copyOfavailabilityDates: {},
  pagesDetailsSectionsData: {},
  pagesFooterSectionsData: {},
  cityList: {},
  minimumStay: {},
};

// demo api
export const fetchPropertyList = (searchParams) => {
  let url = searchParams
    ? `gateways/visitor/api/properties?${searchParams}`
    : 'gateways/visitor/api/properties';

  return (dispatch) => {
    dispatch(updatePropertyList([], true));
    instance({
      method: 'GET',
      url,
    })
      .then((data) => {
        dispatch(updatePropertyList(data, false));
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
};

export const fetchPropertyDetail = (id) => {
  return (dispatch) => {
    instance({
      method: 'GET',
      url: `gateways/visitor/api/properties/${id}`,
    })
      .then((response) => {
        const data = response.data;
        dispatch(propertyDetails(data));
        localStorage.setItem(
          'max_occupancy',
          response?.data ? response.data.max_occupancy : 0
        );
      })
      .catch((error) => {
        handleErrorResponse(error, dispatch);
        // Add error handling here if needed
      });
  };
};

// FETCHING_DATES_AVAILABILITY_VIA_CALENDER

export const fetchingDatesAvailability = (propertyId) => {
  return async (dispatch) => {
    dispatch(checkAvailabilityLoader(true));
    try {
      const response = await axios.get(
        // `${process.env.APP_GATEWAY_URL}/gateways/customer/api/properties/${propertyId}/availability-calendar`
        `${process.env.APP_GATEWAY_URL}/gateways/customer/class/property/functions/getAvailabilityCalendar?property_id=${propertyId}`
      );
      if (response?.data && response?.status == 200) {
        const result = {};
        const minStays = {};
        const data = response?.data;
        data?.forEach((item) => {
          item.is_available = item.is_available ? 1 : 0;
          result[item.date] = item.is_available;
          minStays[item.date] = item.minimumStay;
        });
        const minStayData = Object.fromEntries(
          Object.entries(minStays).map(([date, value]) => {
            return [date, value];
          })
        );
        const modifiedData = Object.fromEntries(
          Object.entries(result).map(([date, value]) => {
            return [date, value];
          })
        );
        dispatch(getMinimumStays(minStayData));
        dispatch(availableDates(modifiedData));
        dispatch(copyAvailableDates(modifiedData));
        dispatch(checkAvailabilityLoader(false));
        sessionStorage.setItem('copyData', JSON.stringify(modifiedData));
      }
    } catch (error) {
      handleErrorResponse(error, dispatch);
      // You might want to dispatch an error action if needed
      // Example: dispatch({ type: 'SET_ERROR', payload: error.message });
    }
  };
};

// UPDATING_PRICING
export const updatingPricing = (data) => {
  const { children, adults, checkin_on, checkout_on, property_id } = data;
  return (dispatch) => {
    dispatch(loadingImage(true));
    instance({
      method: 'GET',
      url: `gateways/customer/api/properties/${property_id}?checkin_on=${checkin_on}&checkout_on=${checkout_on}&adults=${adults}&children=${children}`,
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(loadingImage(false));
          dispatch(fetchingUpdatedPricing(true));
          dispatch(gettingUpdatedPricingRes(response.data));
        }
        // You can handle the response data if needed
        // For example: dispatch({ type: 'UPDATE_SUCCESS', payload: response.data });
      })
      .catch((error) => {
        dispatch(loadingImage(false));
        handleErrorResponse(error, dispatch);
        // You might want to dispatch an error action if needed
        // Example: dispatch({ type: 'SET_ERROR', payload: error.message });
      });
  };
};

// BOOKING
export const booking = (data) => {
  const { bookingId, ...requestData } = data;
  let authorizationNumber = '9720758777';
  return (dispatch) => {
    dispatch(loadingImage(true));
    let apiUrl;
    let requestMethod;

    if (bookingId) {
      apiUrl = `${process.env.APP_GATEWAY_URL}/gateways/customer/api/cart-bookings/${bookingId}`;
      requestMethod = 'PUT';
    } else {
      apiUrl = `${process.env.APP_GATEWAY_URL}/gateways/customer/api/cart-bookings`;
      requestMethod = 'POST';
    }
    instance({
      method: requestMethod,
      url: apiUrl,
      data: requestData,
    })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          dispatch(loadingImage(false));
          dispatch(bookingStatus(true));
          dispatch(
            gettingBookingRes(
              response.data ? response.data?.cart_booking?.id : ''
            )
          );
        }
      })
      .catch((error) => {
        dispatch(loadingImage(false));
        handleErrorResponse(error, dispatch);
        // You might want to dispatch an error action if needed
        // Example: dispatch({ type: 'SET_ERROR', payload: error.message });
      });
  };
};

export const updateTheCalender = (data) => {
  return (dispatch) => {
    dispatch(availableDates(data));
  };
};

export const homePageDetailsSections = (nameSpace, slug) => {
  return (dispatch) => {
    dispatch(loadingImage(true));
    instance({
      method: 'GET',
      url: `gateways/visitor/api/namespaces/${nameSpace}/webPages/${slug}`,
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(loadingImage(false));
          // const modifiedData = {
          //   ImageBlocks: convertArrayToObject(
          //     response?.data?.ImageBlocks,
          //     'slug'
          //   ),
          //   PageBlocks: convertArrayToObject(
          //     response?.data?.PageBlocks,
          //     'name'
          //   ),
          //   Sections: convertArrayToObject(response?.data?.Sections, 'slug'),
          // };
          dispatch(pagesDetailsData(response?.data));
        }
      })
      .catch((error) => {
        dispatch(loadingImage(false));
        handleErrorResponse(error, dispatch);
      });
  };
};

export const footerSections = (nameSpace, slug) => {
  return (dispatch) => {
    dispatch(loadingImage(true));
    instance({
      method: 'GET',
      url: `gateways/visitor/api/namespaces/${nameSpace}/webPages/${slug}`,
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(loadingImage(false));
          // const modifiedData = {
          //   ImageBlocks: convertArrayToObject(
          //     response?.data?.ImageBlocks,
          //     'slug'
          //   ),
          //   PageBlocks: convertArrayToObject(
          //     response?.data?.PageBlocks,
          //     'name'
          //   ),
          //   Sections: convertArrayToObject(response?.data?.Sections, 'slug'),
          // };
          dispatch(pagesFooterData(response?.data));
        }
      })
      .catch((error) => {
        dispatch(loadingImage(false));
        handleErrorResponse(error, dispatch);
      });
  };
};

export const fetchCityList = () => {
  return (dispatch) => {
    instance({
      method: 'GET',
      url: 'gateways/visitor/api/properties/functions/getCityList',
    })
      .then((data) => {
        dispatch(getCityList(data));
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
};

//--------------------REDUCER-------------------------
const propertyListReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROPERTYLIST:
      return {
        ...state,
        propertyListData: action.payload,
        isLoading: action.isLoading,
      };
    case PROPERTY_DETAIL:
      return {
        ...state,
        propertyDetail: action.payload,
      };
    case AVAILABILITY_CALENDER_DATES:
      return {
        ...state,
        availabilityDates: action.payload,
      };
    case FETCHING_UPDATED_PRICING:
      return {
        ...state,
        fetchingUpdatingPricing: action.payload,
      };
    case GETTING_UPDATED_PRICING:
      return {
        ...state,
        gettingUpdatedPricing: action.payload,
      };
    case FETCHING_BOOKING_STATUS:
      return {
        ...state,
        isBookingSuccess: action.payload,
      };
    case GETTING_BOOKING:
      return {
        ...state,
        gettingBookingResponse: action.payload,
      };
    case COPYING_AVAILABILITY_CALENDER_DATES:
      return {
        ...state,
        copyOfavailabilityDates: action.payload,
      };
    case PAGES_DETAILS_SECTION_DATA:
      return {
        ...state,
        pagesDetailsSectionsData: action.payload,
      };
    case PAGES_FOOTER_SECTION_DATA:
      return {
        ...state,
        pagesFooterSectionsData: action.payload,
      };
    case GET_CITY_LIST:
      return {
        ...state,
        cityList: action.payload,
      };
    case GET_MINIMUM_STAYS:
      return {
        ...state,
        minimumStay: action.payload,
      };
    default:
      return state;
  }
};

export default propertyListReducer;
