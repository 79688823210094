import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import ReactPixel from 'react-facebook-pixel';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';

// Import functions for tracking initialization
import {
  initializeClarityTracking,
  initializeGTM,
  initializeHubspotTracking,
} from './components/Common/GTM';

// Import the root reducer
import rootReducer from './reducers';

// Import the main App component
import App from './App';

// Check if the browser is online
const isOnline = navigator.onLine;

// Configure Redux store
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

// console's console log
console.log(
  '%cI%c❤️%cELIVAAS',
  'color: #cccccc; font-size: 100px; font-weight: bold;',
  'color: #ef4444; font-size: 150px; font-weight: bold; line-height: 230px;',
  'color: #cccccc; font-size: 100px; font-weight: bold;'
);
console.log(
  '%cJoin us and build a world where anyone can belong anywhere at %chttps://elivaas.com/explore/careers/',
  'color: #000000; font-size: 12px;font-weight: bold;',
  'color: blue; text-decoration: underline; font-weight: bold;'
);

setTimeout(() => {
  // Initialize Facebook Pixel with your Pixel ID
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false, // enable logs
  };
  ReactPixel.init('297497046149024', options);
  ReactPixel.pageView(); // Track a PageView

  // GTM initialize
  initializeGTM();

  // larity initialize
  initializeClarityTracking();

  // Hubspot initialize
  initializeHubspotTracking(process.env.HUBSPOT_ID);
}, 5000);

// Hubspot initialize

// Use ReactDOM.createRoot instead of ReactDOM.render
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <App />
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
);

// Add event listeners for online/offline events
window.addEventListener('online', () => {
  if (rootElement) {
    rootElement.classList.remove('grayscale'); // Remove the grayscale class for online mode
  }
});

window.addEventListener('offline', () => {
  if (rootElement) {
    rootElement.classList.add('grayscale'); // Add the grayscale class for offline mode
  }
});

// Register the service worker
serviceWorker.register();
