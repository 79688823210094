import {
  Box,
  Card,
  Container,
  IconButton,
  Typography,
  Paper,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Badge,
  Stack,
  Button,
} from '@mui/material';
import { ElivaasLuxe, Elicash, Gold } from '@assets';
import Svg from '@components/Common/Svg';
import Skeleton from '@components/Common/Skeleton';
import Stepper from './Stepper';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useEffect, lazy, Suspense } from 'react';
const WalletFaqs = lazy(() => import('./FAQs'));
const MembershipsSlider = lazy(() => import('./MembershipsSlider'));
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './wallet.scss';

const MobileHeader = lazy(() => import('@components/Common/Mobile/Header'));

const faqs = [
  {
    question: 'What is the value of one Coin?',
    answer:
      'Every guest who completes the booking via the ELIVAAS application and the website will be eligible to earn reward points based on their tier. Reward points will not be available for bookings done via OTA or a Travel Agent. To know more reach out to Us @ +91 9988776655',
  },
  {
    question: 'How many reward points can we earn per booking?',
    answer:
      'YEvery guest who completes the booking via the ELIVAAS application and the website will be eligible to earn reward points based on their tier. Reward points will not be available for bookings done via OTA or a Travel Agent. To know more reach out to Us @ +91 9988776655',
  },
  {
    question: 'Can I earn reward points on my current confirmed booking?',
    answer:
      'Every guest who completes the booking via the ELIVAAS application and the website will be eligible to earn reward points based on their tier. Reward points will not be available for bookings done via OTA or a Travel Agent. To know more reach out to Us @ +91 9988776655',
  },
  {
    question: 'What is the check-in process like?',
    answer:
      'Every guest who completes the booking via the ELIVAAS application and the website will be eligible to earn reward points based on their tier. Reward points will not be available for bookings done via OTA or a Travel Agent. To know more reach out to Us @ +91 9988776655',
  },
  {
    question: 'What should I do if I have a problem during my stay?',
    answer:
      'Every guest who completes the booking via the ELIVAAS application and the website will be eligible to earn reward points based on their tier. Reward points will not be available for bookings done via OTA or a Travel Agent. To know more reach out to Us @ +91 9988776655',
  },
];

export const Wallet = () => {
  useEffect(() => {}, []);

  const handleNavigateToBackPage = () => {
    window?.history.back();
  };

  return (
    <Container className='El-walletMain' disableGutters maxWidth='xl'>
      <div className='El-walletMainWraper'>
        <IconButton
          edge='start'
          color='info'
          aria-label='back'
          onClick={handleNavigateToBackPage}
          size='large'
        >
          <ArrowBackIcon />
        </IconButton>

        {/* top section */}
        <Box className='El-walletTopSection'>
          <Svg src={ElivaasLuxe} className='EL-elivaasLuxe' alt='ElivaasLuxe' />
          <Svg src={Gold} className='EL-memberIconTop' alt='Gold membership' />
          <Typography variant='h6' gutterBottom>
            Congratulations Aayush! <br />
            You are now a <b>SILVER MEMBER</b>
          </Typography>
          {/* top section end */}

          {/* elicash CTA */}
          <Paper className='El-myElivash' elevation={0}>
            <Svg src={Elicash} className='EL-elicashIcon' alt='Elicash' />
            <div className='EL-myCashIn'>
              <Typography component='p' variant='body2'>
                My Elicash
              </Typography>
              <Typography variant='body1' component='p'>
                500
              </Typography>
            </div>
            <IconButton aria-label='delete' size='large'>
              <ChevronRightIcon />
            </IconButton>
          </Paper>
        </Box>
        {/* elicash CTA emd */}

        {/* elicash info card*/}
        <Card className='EL-wallerCard'>
          <List
            className='EL-wallerSpend'
            subheader={
              <Typography component='p' variant='p'>
                <b> You Spent</b> (Last 12 months)
              </Typography>
            }
            sx={{ width: '100%' }}
          >
            {[1, 2].map((value) => (
              <ListItem
                disablePadding
                key={value}
                disableGutters
                secondaryAction={'₹ 83,000'}
              >
                <ListItemText
                  className={value == 1 ? 'EL-cashCompl' : 'EL-cashPending'}
                  primary={
                    <>
                      <Badge showZero={true} color='secondary' /> Stay Completed
                      :
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
          <div className='EL-memberStepper'>
            <Stepper />
          </div>
          <div className='EL-memberUpgrade'>
            <Typography component='h5' variant='h5'>
              Upgrade to <b>GOLD Membership</b>
            </Typography>
            <Stack direction='row' spacing={2}>
              <Button
                className='EL-walletBook'
                size='large'
                variant='contained'
              >
                Book Villa
              </Button>
              <Button
                className='EL-walletPlan'
                fullWidth
                size='large'
                variant='contained'
              >
                Plan Your Next Stay
              </Button>
            </Stack>
          </div>
        </Card>
        {/* elicash cards end */}

        {/* elicash member card*/}
        <div className='EL-elicashSlidermain'>
          <Typography component='h4' variant='h4'>
            Unlock Exclusive Benefits with
          </Typography>
          <Suspense fallback='Loaging' mode='concurrent'>
            <MembershipsSlider
              slidesPerView={1}
              className='EL-memberSlider'
              spaceBetween={0}
              slidesPerViewMobile={1}
            />
          </Suspense>
        </div>
        {/* elicash member card end*/}

        {/* elicash spend info card*/}
        <div className='EL-elivash'>
          <Typography component='h4' variant='h4'>
            You Earn
          </Typography>
          <Paper className='EL-elivashInner' elevation={0}>
            <Typography component='p' variant='body1'>
              <Svg src={Elicash} className='EL-elicash' alt='Elicash' />{' '}
              <b>500 </b> Elicash
            </Typography>
            <Typography variant='body2' component='p'>
              for every ₹ 100 spent on Villa Bookings
            </Typography>
          </Paper>
          <Typography component='p' variant='caption'>
            1 Elicash = ₹ 1
          </Typography>
        </div>
        {/* elicash spend info card end*/}

        {/* faq start  */}
        <div className='EL-walletFaqMain'>
          <Suspense
            fallback={Array.from({ length: 4 }, (_, index) => (
              <div key={`faqSleketon${index}`}>
                <Skeleton component='h4' height={40} variant='text' />
                {Array.from({ length: 4 }, (_, index) => (
                  <div key={`faqinner${index}`}>
                    <Skeleton height={20} component='p' variant='text' />
                    <Skeleton height={15} component='p' variant='text' />
                  </div>
                ))}
              </div>
            ))}
            mode='concurrent'
          >
            <WalletFaqs className='EL-faqWallet' data={faqs} />
          </Suspense>
        </div>
        {/* faq end  */}
      </div>
    </Container>
  );
};
