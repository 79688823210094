import CallBackRequest from '@components/CallBack';
import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Drawer,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  comparePropertyParams,
  getCityNameFromPath,
  getFilterBarValues,
  saveFilterValues,
} from '@utils/common';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PropertyFilterContent from './PropertyFilterContent';
import './propertyFilterbar.scss';
import { useSelector } from 'react-redux';

const PropertyFilterBar = (props) => {
  const {
    title,
    pageType,
    isDrawerOpen,
    setIsDrawerOpen,
    buttonLabel = 'Search',
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const urlPathname = location.pathname;

  const searchParams = new URLSearchParams(location.search);
  const cityName = getCityNameFromPath(urlPathname);
  const checkin_date = searchParams.get('checkin') || '';
  const checkout_date = searchParams.get('checkout') || '';
  const adult_count = Number(searchParams.get('adults')) || 1;
  const children_count = Number(searchParams.get('children')) || 0;

  const [storedSearchValues, setStoredSearchValues] = useState(null);

  const { cityList } = useSelector((state) => state.property);
  const cityListData = cityList?.data;

  useEffect(() => {
    if (pageType === 'home') {
      localStorage.removeItem('filterValues');
      return;
    }
    if (cityListData) {
      const propertiesToCompare = {
        selectedCity: cityName,
        checkInDate:
          checkin_date &&
          dayjs(checkin_date, { format: 'DD MMM, YYYY' }).format(
            'DD MMM, YYYY'
          ),
        checkOutDate:
          checkout_date &&
          dayjs(checkout_date, { format: 'DD MMM, YYYY' }).format(
            'DD MMM, YYYY'
          ),
        numberOfGuests: adult_count,
        numberOfChildren: children_count,
      };
      let valuesDiffer = comparePropertyParams(propertiesToCompare);
      const filterbarValues = getFilterBarValues(
        propertiesToCompare,
        valuesDiffer,
        cityListData
      );

      if (valuesDiffer) {
        saveFilterValues({
          ...propertiesToCompare,
          selectedCity: filterbarValues.city,
        });
      }

      setStoredSearchValues(filterbarValues);
    }
  }, [cityListData]);

  return (
    <div className='El-filterbar-continer'>
      {isMobile ? (
        <>
          <div
            className={
              storedSearchValues || pageType === 'listing'
                ? 'el-search-searchFilledBox'
                : 'el-search'
            }
          >
            <Button
              variant='contained'
              size='large'
              startIcon={
                !storedSearchValues && pageType !== 'listing' && <SearchIcon />
              }
              endIcon={
                (storedSearchValues || pageType === 'listing') && <SearchIcon />
              }
              fullWidth
              onClick={() => setIsDrawerOpen(true)}
            >
              <div className='el-search-content'>
                {storedSearchValues ? (
                  <>
                    <Typography
                      variant='subtitle1'
                      className='el-search-content-header'
                    >
                      {storedSearchValues?.city !== ''
                        ? storedSearchValues?.city
                        : 'All Cities'}
                    </Typography>
                    <div className='el-search-content-body'>
                      <Typography variant='subtitle2'>
                        {storedSearchValues?.checkInDate &&
                        storedSearchValues ? (
                          <>
                            {storedSearchValues?.checkInDate.slice(0, 6)} -
                            {storedSearchValues?.checkOutDate.slice(0, 6)},
                          </>
                        ) : (
                          'Add Check-in/out date ,'
                        )}
                      </Typography>
                      <Typography variant='subtitle2'>
                        {storedSearchValues?.numberOfGuests} Adult(s),
                      </Typography>
                      <Typography variant='subtitle2'>
                        {storedSearchValues?.numberOfChildren} Kid(s)
                      </Typography>
                    </div>
                  </>
                ) : pageType === 'listing' ? (
                  <div className='allCities-search'>
                    <Typography
                      variant='subtitle1'
                      className='el-search-content-header'
                    >
                      All Cities
                    </Typography>
                    <div className='el-search-content-body'>
                      <Typography variant='subtitle2'>
                        Add Check-in/out date , Add Guest(s)
                      </Typography>
                    </div>
                  </div>
                ) : (
                  <Typography
                    variant='subtitle1'
                    className='el-search-content-header'
                  >
                    Search for a city or villa
                  </Typography>
                )}
              </div>
            </Button>
          </div>
          <Drawer
            anchor='bottom'
            open={isDrawerOpen}
            className='mobileSearchDrawer'
            onClose={() => setIsDrawerOpen(false)}
          >
            <PropertyFilterContent
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              pageType={pageType}
              title={title}
              buttonLabel={buttonLabel}
              storedSearchValues={storedSearchValues}
            />
            <CallBackRequest
              className={'EL-modalCallback'}
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
            />
          </Drawer>
        </>
      ) : (
        <>
          <div className='el-search-container'>
            <div className='property-filter-bar'>
              <PropertyFilterContent
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
                pageType={pageType}
                title={title}
                buttonLabel={buttonLabel}
                storedSearchValues={storedSearchValues}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PropertyFilterBar;
